import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from 'react-tooltip'
import {update_subcription_api } from "../../api";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Module name is required !"),
    app: yup
      .array()
      .required("Please select application !")
      .min(1, "Please select application !"),
    no_of_users: yup
      .string()
      .required("Enter number of users !")

  })
  .required();

function EditSubscription(props) {
  const [visible, setVisible] = useState(false);
  const { register, handleSubmit, formState, control, reset, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    console.log("props", props);
    if (visible == true) {
      console.log("inside", props);
      let a = props.app_list.filter((item) => item.app_id == props.data.app_id);
      console.log("a",a)
      setValue("app", a)
    }
  }, [visible])
  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data !== "") {
      const reqPayload = {
        app_id: data.app[0].app_id,
        subscription_name: data.name,
        no_of_users: data.no_of_users,
        module_list:[]
      };

      console.log("reqPayload", reqPayload);

      update_subcription_api( {subs_id:props.data.subs_id},reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Subscription updated Successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchSubcription();
          }
        },
        (err) => {
          console.log("err.response.status", err.response.status);
          if (err.response.data.data.statusCode === 400) {
            //onsole.log("err.response", err.response);
            toast.error(err.response.data.data.response, {
              autoClose: 2000,
            });
          }else{
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        }
      )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>

      <Tooltip id={"edit-tooltip"} place="top" />
      <div data-tooltip-id={"edit-tooltip"}
        data-tooltip-content="Edit Subscription" className="editIcon me-2">
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
      {/* </OverlayTrigger> */}
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Update Subcription"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form action="">
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">
                Subscription Name
                <span className="text-danger">
                <i style={{ color: "red" }}>*</i>
                </span>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="name"
                    name="name"
                    placeholder="Enter Subscription name"
                    defaultValue={props.data.subscription_name}
                    {...register("name")}
                  />
                  <span className="text-danger">
                    {errors.name?.message}
                  </span>
                </div>
              </div>
              <div className="form-group row mt-2">
              <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Application"}
              <span className="text-danger">
                <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
              <Controller
                name="app"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead"
                    labelKey="app_name"
                    multiple={false}
                    options={props.app_list}
                   // loading={app_list.loading}
                    placeholder="Select application"
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.app ? true : false}
                  />
                )}
              />
              <span className="text-danger">{errors.app?.message}</span>
              </div>
            </div>
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">
                Number of Users
                <span className="text-danger">
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-8">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    id="no_of_users"
                    name="no_of_users"
                    placeholder="Enter number of users"
                    defaultValue={props.data.no_of_users}
                    {...register("no_of_users")}
                  />

                </div>
                <span className="text-danger">{errors.no_of_users?.message}</span>
              </div>
              
            </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  );
}

export default EditSubscription;

