import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faAdd } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from 'react-router-dom';
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Trash2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import {  get_module_api, update_role_api } from '../../api';
import { Tooltip } from "react-tooltip";

const schema = yup
    .object()
    .shape({
        roleName: yup.string().required("Please enter role name"),
        app: yup
            .array()
            .required("Please select application !")
            .min(1, "Please select application !"),
        items: yup.array().of(
            yup.object().shape({
                module: yup.array().nullable()
                    .min(1, "Please select module")
                    .required("Please select module"),
            })
        ),
    })
    .required();

function UpdateRole() {

    let navigate = useNavigate();
    let location = useLocation();
    const [moduList, setModuList] = useState({ data: [], loading: false });


    const { register, handleSubmit, formState, control, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            items: [{ module: [] }]
        },
    }); // initialise the hook

    let { errors } = formState;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
    });


    useEffect(() => {
        console.log("location", location);
        let a=location.state.app_list.filter((item)=>item.app_id==location.state.app_id);
        if (location.state?.module_list.length > 0) {
            const module_list = [...location.state.module_list];
            const newModList = module_list.map(item => {
                let obj = {};
                obj.module_name = item.module;
                let obj_main = { ...item, module: [obj] }
                return obj_main
            })
            console.log("newModList", newModList);
            // setValue('items', newModList);
            //console.log("newModList",newModList)
            //const newModList=[]
          
            reset({
                 app:a,
                roleName: location.state.name,
                items: newModList
            })
        } else {
            reset({
                app:a,
                roleName: location.state.name,
                items: []
            })
        }
        fetchModList();
    
    }, [])

    function fetchModList() {
        setModuList({ ...moduList, data: [], loading: true });

        get_module_api().then((res) => {
            console.log("res update", res);
            setModuList({ ...moduList, data: res.data.data.response.data, loading: false });
        }, (err) => {
            setModuList({ ...moduList, data: [], loading: false });
            console.log("err", err);
        })
    }

    
    const isModuleNamesUnique = items => {
        const moduleNames = new Set();

        for (const item of items) {
            for (const mod of item.module) {
                if (moduleNames.has(mod.module_name)) {
                    return true; // Found a duplicate module_name
                }
                moduleNames.add(mod.module_name);
            }
        }

        return false;
    };

    const onSubmit = (data) => {
        console.log("data", data);

        if (isModuleNamesUnique(data.items)) {
            toast.error("Duplicate modules are not allowed", {
                autoClose: 2000,
            });
            return;
        } else {
            const filteredData = data.items.filter(item => {
                return item.write || item.read || item.delete || item.view || item.report;
            });
            console.log("filteredData", filteredData);

            if (filteredData.length > 0) {
                const resData = filteredData.map(item => {
                    item.module = item.module[0].module_name
                    return item
                });
                console.log("resData", resData);
                const payload = {
                    app_id:data.app[0].app_id,
                    name: data.roleName,
                    module_list: resData
                }
                console.log("payload", payload);

                update_role_api({ role_id: location.state.role_id },payload).then((res) => {
                    console.log("res", res);

                    toast.success("Role updated successfully", {
                        autoClose: 1000,
                    });
                    setTimeout(() => {
                        navigate("/role");
                    }, [1000])
                }, (err) => {
                    console.log("err", err);
                    toast.error("Something went wrong", {
                        autoClose: 2000,
                    });
                })
            } else {
                toast.error("Module options are uncheck", {
                    autoClose: 2000,
                });
            }
        }

    }


    return (
        <React.Fragment>
            <ToastContainer />
            <div className='update_role mb-4'>
                <div className='card'>

                    <div className='card-header bg-grey d-flex align-items-center'>
                        <button className='btn btn-yellow' onClick={() => {
                            navigate("/role");
                        }}><FontAwesomeIcon icon={faArrowLeft} size="sm" /></button>

                        <p className='mb-0 ms-3' style={{ fontSize: '24px' }}>Update Role</p>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-4'>
                                <div className="form-group row mt-2">
                                    <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Role Name"}
                                        <span className="text-danger">
                                            <i style={{ color: "red" }}>*</i>
                                        </span>
                                    </label>
                                    <div className="col-md-8">
                                        <input
                                            className={errors.roleName ?
                                                'is-invalid form-control form-control-sm d-block'
                                                : 'form-control form-control-sm'}
                                            type='text'
                                            placeholder='Enter role name'
                                            {...register("roleName")}
                                        />

                                        {errors.roleName
                                            && <span className='text-danger err-msg'>{errors.roleName.message}</span>}
                                    </div>
                                </div>


                            </div>

                            <div className='col-4'>
                                <div className="form-group row mt-2">
                                    <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Application"}
                                        <span className="text-danger">
                                            <i style={{ color: "red" }}>*</i>
                                        </span>
                                    </label>
                                    <div className="col-md-8">
                                        <Controller
                                            name="app"
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Typeahead
                                                    id="basic-typeahead"
                                                    size='sm'
                                                    labelKey="app_name"
                                                    multiple={false}
                                                    options={location.state.app_list}
                                                   // loading={app_list.loading}
                                                    placeholder="Select application"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    isInvalid={errors.app ? true : false}
                                                />
                                            )}
                                        />
                                        <span className="text-danger">{errors.app?.message}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive py-3" style={{ overflowX: 'unset' }}>
                                <table className="table table-bordered">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">{"Module Name"}</th>
                                            <th className="text-center" scope="col">{"View"}</th>
                                            <th className="text-center" scope="col">{"Read"}</th>
                                            <th className="text-center" scope="col">{"Write"}</th>
                                            <th className="text-center" scope="col">{"Delete"}</th>
                                            <th className="text-center" scope="col">{"Report"}</th>
                                            <th className="text-center" scope="col">{"Action"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map(({ id }, index) => (
                                            <tr key={id} className="f-18">
                                                <td>
                                                    <Controller
                                                        name={`items[${index}].module`}
                                                        control={control}
                                                        // defaultValue={[{ module_name: location.state.module_list[index].module[0].module_name }]}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <Typeahead
                                                                size="sm"
                                                                id="basic-typeahead"
                                                                labelKey="module_name"
                                                                multiple={false}
                                                                options={moduList.data}
                                                                isLoading={moduList.loading}
                                                                // defaultSelected={[{ module_name: location.state.module_list[index].module[0].module_name }]}
                                                                placeholder="Select"
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                selected={value}
                                                                isInvalid={errors.items?.[index]?.module ? true : false}
                                                            />
                                                        )}
                                                    />


                                                    {errors.items?.[index]?.module
                                                        && <span className='text-danger err-msg'>{errors.items?.[index]?.module.message}</span>}

                                                    {index + 1 == fields.length ?
                                                        <div>
                                                            <button
                                                                style={{ fontSize: "16px" }}
                                                                className="btn btn-sm btn-pink px-2 mt-2"
                                                                onClick={() => append({})}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{ marginRight: "5px" }}
                                                                    icon={faAdd}
                                                                    size="sm"
                                                                /> Add
                                                            </button>
                                                        </div>
                                                        : null}
                                                </td>

                                                <td style={{ verticalAlign: 'top' }}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                            {...register(`items[${index}].view`)}
                                                            onChange={(e) => {
                                                                // Set "read" checkbox based on "view" checkbox
                                                                console.log("e.target.checked", e.target.checked);
                                                                setValue(`items[${index}].read`, e.target.checked);
                                                            }}
                                                        />

                                                    </div>
                                                </td>

                                                <td style={{ verticalAlign: 'top' }}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                            {...register(`items[${index}].read`)}
                                                        />

                                                    </div>
                                                </td>

                                                <td style={{ verticalAlign: 'top' }}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                            {...register(`items[${index}].write`)}
                                                        />

                                                    </div>
                                                </td>

                                                <td style={{ verticalAlign: 'top' }}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                            {...register(`items[${index}].delete`)}
                                                        />

                                                    </div>
                                                </td>

                                                <td style={{ verticalAlign: 'top', }}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                            {...register(`items[${index}].report`)}
                                                        />
                                                    </div>
                                                </td>


                                                <td style={{ width: "70px", textAlign: 'center', verticalAlign: "top" }}>
                                                   

                                                    <Tooltip id={"delete-tooltip" + index} place="top" />
                          <div
                            data-tooltip-id={"delete-tooltip" + index}
                            data-tooltip-content="Delete Module"
                            onClick={index > 0 ? () => remove(index) : null}
                            className="deleteIcon m-1 cursor_pointer"
                          >
                            <Trash2 color="#FFFFFF" size={18} />
                          </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                            </div>

                        </div>
                    </div>

                    <div className='card-footer py-3'>
                        <button className="btn btn-yellow px-4 float-end" onClick={handleSubmit(onSubmit)}>
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default UpdateRole