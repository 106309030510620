import React, { useState, useEffect, useReducer } from "react";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";
import { Typeahead } from "react-bootstrap-typeahead";

import EditModule from "./EditModule";
import { delete_module_api, get_app, get_module_api, get_screen_api, save_module_api } from "../../api";
import { calMaxPage } from "../../common/Functions/CommonFunctions";
import AddScreen from "./AddScreen";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Module name is required !"),
    app: yup
      .array()
      .required("Please select application !")
      .min(1, "Please select application !"),
      screen: yup
      .array()
      .required("Please select screen !")
      .min(1, "Please select screen !"),
  })
  .required();

function Module() {

  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [apiBySearch, setApiBySearch] = useState(false);
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [app_list, setApp_list] = useState({ data: [], loading: false })
  const [screen_list, setScreen_list] = useState({ data: [], loading: false })

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const initialState = {
    loading: false,
    error: "",
    moduleList: [],
    itemsPerPage: 10,
    page: 0,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          moduleList: action.moduleList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: "HANDLE_ACTION",
      page: state.page,
      loading: true,
      error: state.error,
      moduleList: state.moduleList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchModule();
    fetch_app();
    fetchScreen();
  }, [state.page, state.itemsPerPage]);

  function fetchModule() {
    dispatch({
      type: "HANDLE_ACTION",
      page: state.page,
      loading: true,
      error: state.error,
      moduleList: state.moduleList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      offset: state.page,
      limit: state.itemsPerPage,
    };

   

    //console.log("reqPayload", reqPayload);


    get_module_api(reqPayload).then(
      (res) => {
        console.log("module res", res);
        console.log(
          "calMaxPage(res.data.data.total)",
          // calMaxPage(res.data.total)
          calMaxPage(res.data.data.response.total)
        );
        setTotalRecords(res.data.data.response.total);

        dispatch({
          type: "HANDLE_ACTION",
          page: state.page,
          loading: false,
          error: state.error,
          moduleList: res.data.data.response.data,
          itemsPerPage: state.itemsPerPage,
          maxPage: calMaxPage(res.data.data.response.total),
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 5000 });
        }
      }
    );
  }


  function fetchScreen() {

    setScreen_list({ data: [], loading: true })
    get_screen_api().then(
      (res) => {
        console.log("Screen res", res);

        setScreen_list({ data: res.data.data.response.data, loading: false })

      },
      (err) => {
        console.log("err", err);

        setScreen_list({ data: [], loading: false })
      }
    );
  }

  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data !== "") {
      const reqPayload = {
        app_id: data.app[0].app_id,
        module_name: data.name,
        module_information: data.remark,
        screens:data.screen
      };

      console.log("reqPayload", reqPayload);


      save_module_api(reqPayload)
        .then(
          (res) => {
            console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Module created Successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              fetchModule();
              reset();
            }
          },
          (err) => {
            console.log("err.response.status", err.response);
           
            if (err.response.data.data.statusCode === 400) {
              //onsole.log("err.response", err.response);
              toast.error(err.response.data.data.response, {
                autoClose: 2000,
              });
            }else{
              toast.error("Something went wrong !", {
                autoClose: 2000,
              });
            }
          }
        )

    } else {
      errors.showMessages();
    }
  };

  const fetch_app = () => {
    setApp_list({ data: [], loading: true });

    get_app({}).then((res) => {
      console.log("res", res.data.data.response);
      setApp_list({ data: res.data.data.response, loading: false });
    })
      .catch((err) => {
        console.log("err", err);
        setApp_list({ data: [], loading: false });
      })
  }
 
  // Deleting NC Status
  const delModule = (val) => {
    //console.log("val", );
    SweetAlert.fire({
      title: "Are you sure ?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          module_id: val.module_id,
        };
        delete_module_api(payload).then(
          (res) => {
            console.log("res", res);
            toast.success("Module deleted successfully !", {
              autoClose: 3000,
            });

           
              fetchModule();
            

          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }


  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{"Add Module"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
            <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Application"}
                  <span className="text-danger">
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-8">
                  <Controller
                    name="app"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="app_name"
                        multiple={false}
                        options={app_list.data}
                        loading={app_list.loading}
                        placeholder="Select application"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.app ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.app?.message}</span>
                </div>
              </div>
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">
                  Module Name
                  <span className="text-danger">
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="name"
                    name="name"
                    placeholder="Enter module name"
                    {...register("name")}
                  />
                  <span className="text-danger">
                    {errors.name?.message}
                  </span>
                </div>
              </div>
              
              
              <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                {"Screen"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="screen"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="screen_name"
                      multiple={true}
                      options={screen_list.data}
                      loading={screen_list.loading}
                      placeholder="Select screen"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.screen ? true : false}
                    />
                  )}
                />
                <span className="text-danger">{errors.screen?.message}</span>
              </div>
            </div>
            <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">
                  Module Information
                </label>
                <div className="col-md-8">
                  <textarea
                    type="text"
                    className="form-control form-control-sm"
                    id="remark"
                    name="remark"
                    placeholder="Enter remark"
                    rows={2}
                    {...register("remark")}
                  />

                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow inter-bold"
            >
              {"Save"}
            </button>
          </Modal.Footer>
        </Modal>
        
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 inter-bold"
                style={{ fontSize: "14px" }}
              >
                Search
              </label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search by name"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      fetchModule("search", e.target.value);
                      setApiBySearch(true);
                    } else {
                      fetchModule();
                    }
                  }}
                />
              </div>
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button className="btn btn-sm btn-yellow">
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                Clear Filter
              </button>
              {/* <button
                          className="btn btn-sm btn-grey ms-0 ms-3 px-4"
                      >
                          <FontAwesomeIcon style={{ color: "#fff" }} icon={faSearch} size="sm" />{" "}
                          Search
                      </button> */}
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <label className="page-heading">{"Module"}</label>
            <div>
              {/* <button
                className="btn btn-grey px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faFilter}
                  size="sm"
                />
                Filter Menu
              </button> */}

              <button
                className="btn btn-pink px-4"
                onClick={() => {
                  setVisible(true);
                  reset();
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                {"Add Module"}
              </button>
            </div>
          </div>
          <div className="card border-0 mt-3">
            <div className="card-body">
              {/* <div className="d-flex justify-content-start mb-3 me-3">
                <div>
                  <p className="mb-0">
                    Show
                    <select
                      className="mx-1"
                      defaultValue={"10"}
                      // onChange={(e) => {
                      //   setEntriesPerPage(e.target.value);
                      //   fetchProdConfig(page, e.target.value);
                      // }}
                      onChange={(e) => {
                        dispatch({
                          type: "HANDLE_ACTION",
                          page: 1,
                          loading: state.loading,
                          error: state.error,
                          moduleList: state.moduleList,
                          itemsPerPage: e.target.value,
                          maxPage: state.maxPage,
                        })
                        setSrNo(0);
                      }

                      }
                    >
                      <option value={"10"}>10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </p>
                </div>
              </div> */}
              <div className="">
                {state.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : state.moduleList && state.moduleList.length > 0 ? (
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-sm"
                      width={"100%"}
                    >
                      <thead className="table-grey text-ceter inter">
                        <tr className="f-14">
                          <th scope="col" width="15%">
                            {"Sr. No"}
                          </th>
                          <th scope="col">{"Module Name"}</th>
                          <th scope="col">{"Module Information"}</th>
                          <th scope="col" width="15%">
                            {"Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="inter">
                        {state.moduleList.map((val, i) => (
                          <tr key={i} >
                            <td>{i + srNo + 1}</td>
                            <td>{val.module_name}</td>
                            <td>{val.module_information ? val.module_information : "NA"}</td>
                            <td>
                              <EditModule
                                data={val}
                                fetchModule={fetchModule}
                                app_list={app_list.data}
                                screen_list={screen_list.data}
                              />
                              <Tooltip id={"del-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"del-tooltip" + i}
                                data-tooltip-content="Delete Module"
                                className="deleteIcon me-2"
                                onClick={() => delModule(val)}
                              >
                                <Trash2 color="#FFFFFF" size={18} />


                              </div>
                            {/* <AddScreen
                              data={val}
                              fetchModule={fetchModule}
                              screen_list={screen_list.data}
                            /> */}


                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : apiBySearch ? (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"No Module found !"}
                  </p>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Module list is empty"}
                  </p>
                )}

                {state.loading ? null : (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className='mb-0'>{`Showing ${parseInt(srNo) + 1} to ${Math.min(parseInt(state.itemsPerPage) + parseInt(srNo), totalRecords)} of ${totalRecords} entries`}</p>
                    <Pagination>
                      <Pagination.Prev
                        disabled={(state.page+1) === 1 ? true : false}
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page - 1,
                            loading: state.loading,
                            error: state.error,
                            moduleList: state.moduleList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC - parseInt(state.itemsPerPage));
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{state.page+1}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                        (  state.page+1) === state.maxPage || state.maxPage === 0
                            ? true
                            : false
                        }
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page + 1,
                            loading: state.loading,
                            error: state.error,
                            moduleList: state.moduleList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC + parseInt(state.itemsPerPage));
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Module