import axios from 'axios';

// Create an Axios instance
const client2 = axios.create({
  baseURL: process.env.REACT_APP_API_DOCKER_URL, // Replace with your API base URL
});

// Define a function to retrieve the authentication token (e.g., from local storage)
const getAuthToken = () => {
  return localStorage.getItem('access');
};

// Request interceptor
client2.interceptors.request.use(
  (config) => {
    // Get the authentication token
    const authToken = getAuthToken();

    // Add the token to the request headers if it exists
    if (authToken) {
        config.headers = {
            'X-ID-TOKEN': `${authToken}`
          };
    }

   
    return config;

    
  },
  (error) => {
         console.log("err",error)
    return Promise.reject(error);
  }
);
client2?.interceptors.response.use(
  (res) => {
  console.log("rres",res);
  return Promise.resolve(res);
  },
  
  (error) => {
    
    // Handle 401 Unauthorized
    if (error?.response?.status === 401) {
      console.error('Unauthorized request. Please log in.');
      localStorage.clear();
        window.location.replace(`${process.env.PUBLIC_URL}/login`);
      
    }

    // Handle 403 Forbidden
    if (error?.response?.status === 403) {
      localStorage.clear();
      window.location.replace(`${process.env.PUBLIC_URL}/login`);
    }

    // Handle other errors
    if (error?.response?.status !== 401 && error?.response?.status !== 403) {
      console.error('An error occurred:', error.message);
      // localStorage.clear();
      // window.location.replace(`${process.env.PUBLIC_URL}/login`);
    }

    return Promise.reject(error);
  }
);

export default client2