
import './App.css'
import Login from './Authentication/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-toastify/dist/ReactToastify.css';
import DefaultLayout from './DefaultLayout/DefaultLayout';
import "react-datepicker/dist/react-datepicker.css";


function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Suspense fallback={<p>loading</p>}>
          <Routes>



            <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />



            <Route path="*" element={<DefaultLayout />} />


          </Routes>
        </Suspense>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
