import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import loginImg from '../../src/assets/images/loginImg.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Spinner from 'react-bootstrap/Spinner';
import axios from "axios";

const schema = yup
    .object()
    .shape({
        username: yup.string().required("Username is required !"),
        password: yup.string().required("Password is required !"),
    })
    .required();

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [loginClick, setLoginClick] = useState(false);

    let navigate = useNavigate();

    const { register, handleSubmit, formState, getValues,setError } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",

    }); // initialise the hook

    const { errors } = formState;

    const url = process.env.REACT_APP_API_AUTH_URL



    const onSubmit = (data) => {
        if (data !== "") {
            console.log("data", data);

            setLoginClick(true);
            axios.post(`${url}login/user`, data).then((res) => {
                console.log("res", res);
                // console.log("data", res.data.data);
                // console.log("token", res.data.data.response.accessToken)
                localStorage.setItem("access", res.data.data.response.idToken)
                localStorage.setItem("accessToken",res.data.data.response.accessToken)
                setLoginClick(false);
                navigate("/application")
            })
                .catch((err) => {
                    setLoginClick(false);
                    console.log("err", err)
                    console.log("err",err.response.status);
                    setError('password', {
                        type: 'manual',
                        message: 'Please check your username and password !',
                      });
                    // if(err.response.status==400){
                    //     toast.error("Username or password is wrong !",
                    //     {autoclose:1000}
                    //     )
                    // }else{
                    //     toast.error("Username or password is wrong !",
                    //     {autoclose:1000}
                    //     )
                    // }
                })

        } else {
            errors.showMessages();
            setLoginClick(false);
        }
    };
    return (
        <React.Fragment>
            <ToastContainer />

            <div className='login_page d-flex justify-content-center align-items-center'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-6 order-2 order-md-1'>

                            <p className='inter-bold mt-4 mt-md-0' style={{ fontSize: '36px' }}>Producus</p>

                            <div className='form_div mx-auto' style={{ maxWidth: '300px' }}>
                                <div className='d-flex justify-content-between mb-4'>
                                    <p style={{ fontSize: "24px" }} className='mb-0 inter-bold'>Super-Admin</p>
                                    {/* <p className='mb-0 text_gold mt-1'>Sign Up</p> */}
                                </div>
                                <div style={{ width: "300px" }}>
                                    <form>
                                        <div className="col">
                                            <div className="input-group">
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    placeholder='Username or Email'
                                                    {...register("username")}
                                                    disabled={loginClick}
                                                    id="username"
                                                />
                                            </div>
                                            <span className="text-danger" style={{ fontSize: '14px' }}>
                                                {errors.username?.message}
                                            </span>
                                        </div>
                                        <div className="col mt-3">
                                            <div className="input-group">
                                                <input
                                                    className='form-control '
                                                    type={showPassword ? "text" : "password"}
                                                    placeholder='Password'
                                                    {...register("password")}
                                                    disabled={loginClick}
                                                    id="password"
                                                    name="password"
                                                />
                                                <span className="input-group-text">
                                                    {showPassword ?
                                                        <FontAwesomeIcon
                                                            style={{ color: "#212529", cursor: 'pointer' }}
                                                            icon={faEyeSlash}
                                                            size="sm"
                                                            onClick={() => setShowPassword(false)}
                                                        />
                                                        :
                                                        <FontAwesomeIcon
                                                            style={{ color: "#212529", cursor: 'pointer' }}
                                                            icon={faEye}
                                                            size="sm"
                                                            onClick={() => setShowPassword(true)}
                                                        />
                                                    }
                                                </span>
                                            </div>
                                            <span className="text-danger" style={{ fontSize: '14px' }}>
                                                {errors.password?.message}
                                            </span>
                                        </div>

                                        <button
                                            className='btn btn-pink w-100 mt-4 inter-bold'
                                            type="submit"
                                            onClick={handleSubmit(onSubmit)}
                                            style={{ letterSpacing: '1px' }}
                                            disabled={loginClick}
                                        >LOGIN {loginClick ? <Spinner animation="border" size="sm" /> : null} </button>

                                        {/* <p className='mb-4 mt-4'
                                            style={{ fontSize: '14px' }}>Forgot Your Password? <span className='text_gold'>Click Here</span></p> */}
                                    </form>
                                </div>
                            </div>


                        </div>
                        <div className='col-12 col-md-6 order-1 order-md-2 '>

                            <div className='position-relative'>
                               
                                <p className='text_over_img inter-bold'>Elevate Your Production Standards
                                    with our integrated production control and quality management solution</p>
                                    
                                <img id='login_img' src={loginImg} alt=''
                                    className='img-fluid' />
                            </div>
                           
                        </div>
                        {/* <div className='position-relative'>

                            <div className='text_over_img inter-bold'>Elevate Your Production Standards
                                with our integrated production control and quality management solution
                                <br />
                                <p className='pink_rectangle mt-3'></p>
                            </div>
                            <img id='login_img' src={loginImg} alt=''
                                className='img-fluid' />
                        </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Login