import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

import { Pagination } from "react-bootstrap";
import { Edit2, Trash2 } from "react-feather";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import {
  get_channel_master_api,
  delete_channel_master_api,
  save_channel_master_api,
} from "../../api";
import { calMaxPage } from "../../common/Functions/CommonFunctions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UpdateChannelMaster from "./UpdateChannelMaster";

const schema = yup
  .object()
  .shape({
    channel_domain_url: yup.string().required("Channel URL is required !"),
    channel_description: yup.string().required("Channel description is required !"),
  })
  .required();
export function ChannelMaster() {
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [visible, setVisible] = useState(false);

  const [channelList, setchannelList] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    fetchChannels(page, entriesPerPage);
  }, []);
  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible]);

  function fetchChannels(a, b) {
    setchannelList({ ...channelList, data: [], loading: true });

    const payload = {
      pageSize: b,
      page: a,
    };

    get_channel_master_api(payload).then(
      (res) => {
        console.log("res", res.data.data);
        setchannelList({
          ...channelList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.totalCount,
        });
        setMaxPage(calMaxPage(res.data.totalCount));
      },
      (err) => {
        setchannelList({
          ...channelList,
          data: [],
          loading: false,
          totalRecords: "",
        });
        console.log("err", err);
      }
    );
  }

  const delChannel = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure to delete \n" + "this record" + " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_channel_master_api({ uuid: val.uuid }).then(
          (res) => {
            console.log("res", res);
            toast.success("Channel deleted successfully", {
              autoClose: 2000,
            });

            let value = channelList.totalRecords % 10;
            if (value == 1 && page !== 1) {
              fetchChannels(page - 1, 10);
              setPage(page - 1);
              setSrNo(srNo - 10);
            } else {
              fetchChannels(page, 10);
            }
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  const onSubmit = (data) => {
    console.log("data", data);
    if (data !== "") {
      const reqPayload = {
        channel_domain_url: data.channel_domain_url,
        channel_description: data.channel_description,
      };

      console.log("reqPayload", reqPayload);

      save_channel_master_api(reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Channel created successfully !", {
              autoClose: 2000,
            });
            fetchChannels(page, 10);
            setVisible(false);
            reset();
          }
        },
        (err) => {
          toast.error("Something went wrong !", {
            autoClose: 2000,
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Add Channel"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Channel Domain URL
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="channel_domain_url"
                  name="channel_domain_url"
                  placeholder="Enter channel url"
                  {...register("channel_domain_url")}
                />
                <span className="text-danger">
                  {errors.channel_domain_url?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Channel Description
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="channel_description"
                  name="channel_description"
                  placeholder="Enter channel description"
                  {...register("channel_description")}
                />

                <span className="text-danger">
                  {errors.channel_description?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="roles">
        <div className="d-flex justify-content-between">
          <h1 className="page-heading inter-bold">{"Channels"}</h1>
          <button
            className="btn btn-pink px-4"
            onClick={() => {
              setVisible(true);
            }}
          >
            <FontAwesomeIcon
              style={{ color: "#fff" }}
              icon={faPlus}
              size="sm"
            />{" "}
            Create New Channel
          </button>
        </div>

        <div className="row">
          <div className="col-12">
            {/* <div className='entriesPerPage inter-bold d-flex align-items-end'>
                            <p className='mb-0'>Show
                                <select className='mx-1' defaultValue={'10'} onChange={(e) => {
                                    setEntriesPerPage(e.target.value);
                                    fetchChannels(page, e.target.value)
                                }}>
                                    <option value={'10'}>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>Entries</p>

                        </div> */}
            <div className="card border-0 mt-3 mb-4">
              <div className="card-body">
                {channelList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : channelList.data?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table mt-2 table-striped table-sm">
                      <thead className="table-grey  inter-bold">
                        <tr className="">
                          <th scope="col">{"Sr. No"}</th>
                          <th scope="col">{"Channel URL"}</th>
                          <th scope="col">{"Channel Description"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody className=" inter">
                        {channelList.data.map((val, i) => (
                          <tr key={i}>
                            <td style={{ verticalAlign: "middle" }}>
                              {parseInt(i) + 1 + parseInt(srNo)}
                            </td>
                            <td>{val.channel_domain_url}</td>
                            <td>{val.channel_description}</td>

                            <td className="">
                              <UpdateChannelMaster
                                data={val}
                                fetchChannels={fetchChannels}
                                page={page}
                              />

                              <Tooltip id={"delete-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"delete-tooltip" + i}
                                data-tooltip-content="Delete Channel"
                                onClick={() => delChannel(val)}
                                className="deleteIcon m-1 cursor_pointer"
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-danger mb-0">
                    No Channel's Found
                  </p>
                )}

                {channelList.loading ? null : channelList.data?.length > 0 ? (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
                      channelList.data.length +
                      entriesPerPage * page -
                      entriesPerPage
                    } of ${channelList.totalRecords} entries`}</p>
                    <Pagination>
                      <Pagination.Prev
                        disabled={page === 1 ? true : false}
                        onClick={() => {
                          fetchChannels(page - 1, 10);
                          setSrNo((prevC) =>
                            page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                          );
                          setPage(page - 1);
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{page}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          page === maxPage ||
                          maxPage === 0 ||
                          entriesPerPage > channelList.data.length
                            ? true
                            : false
                        }
                        onClick={() => {
                          // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                          fetchChannels(page + 1, 10);
                          setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                          setPage(page + 1);
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
