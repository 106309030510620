import React, { useState, useEffect, useReducer } from "react";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Download, Edit2, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";

import EditScreen from "./EditScreen";
import {
  get_app,
  get_json_api,
  get_screen_api,
  save_screen_api,
} from "../../api";
import { calMaxPage } from "../../common/Functions/CommonFunctions";
import { useNavigate } from "react-router-dom";

function Screen() {
  const [visible, setVisible] = useState(false);
  let navigate = useNavigate();

  const [apiBySearch, setApiBySearch] = useState(false);
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [lable_list, setLablelist] = useState([]);

  const initialState = {
    loading: false,
    error: "",
    ScreenList: [],
    itemsPerPage: 10,
    page: 0,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          ScreenList: action.ScreenList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: "HANDLE_ACTION",
      page: state.page,
      loading: true,
      error: state.error,
      ScreenList: state.ScreenList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchScreen();
    fetchJson();
  }, [state.page, state.itemsPerPage]);

  function fetchScreen() {
    dispatch({
      type: "HANDLE_ACTION",
      page:  state.page,
      loading: true,
      error: state.error,
      ScreenList: state.ScreenList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      offset:  state.page ,
      limit: state.itemsPerPage,
    };

   

    

    get_screen_api(reqPayload).then(
      (res) => {
        console.log("Screen res", res);
        console.log(
          "calMaxPage(res.data.data.total)",
          // calMaxPage(res.data.total)
          calMaxPage(res.data.data.response.total)
        );
        setTotalRecords(res.data.data.response.total);

        dispatch({
          type: "HANDLE_ACTION",
          page:   state.page,
          loading: false,
          error: state.error,
          ScreenList: res.data.data.response.data,
          itemsPerPage: state.itemsPerPage,
          maxPage:calMaxPage(res.data.data.response.total),
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 5000 });
        }
      }
    );
  }

  const fetchJson = () => {
    get_json_api()
      .then((res) => {
        console.log("res", res);
        console.log("array of json", res.data.data.response[0].json.data);
        const extractedData = extractScreenLabels(
          res.data.data.response[0].json.data
        );
        console.log("extractedData", extractedData);
        setLablelist(extractedData);
      })
      .catch((err) => {
        setLablelist([]);
        console.log("err", err);
      });
  };

  const extractScreenLabels = (dataArray) => {
    const resultArray = [];

    dataArray.forEach((module) => {
      module.screen_list.forEach((screen) => {
        const { screen_name, labelList } = screen;
        resultArray.push({ screen_name, labelList });
      });
    });

    return resultArray;
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="min-vh-100" id="root_div_main">
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 inter-bold"
                style={{ fontSize: "14px" }}
              >
                Search
              </label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search by name"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      fetchScreen("search", e.target.value);
                      setApiBySearch(true);
                    } else {
                      fetchScreen();
                    }
                  }}
                />
              </div>
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button className="btn btn-sm btn-yellow">
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                Clear Filter
              </button>
              {/* <button
                          className="btn btn-sm btn-grey ms-0 ms-3 px-4"
                      >
                          <FontAwesomeIcon style={{ color: "#fff" }} icon={faSearch} size="sm" />{" "}
                          Search
                      </button> */}
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <label className="page-heading">{"Screen"}</label>
            <div>
              {/* <button
                className="btn btn-grey px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faFilter}
                  size="sm"
                />
                Filter Menu
              </button> */}

              <button
                className="btn btn-pink px-4"
                onClick={() => {
                  navigate("/add-screen");
                  // setVisible(true);
                  // reset();
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                {"Add Screen"}
              </button>
            </div>
          </div>
          <div className="card border-0 mt-3">
            <div className="card-body">
              {/* <div className="d-flex justify-content-start mb-3 me-3">
                <div>
                  <p className="mb-0">
                    Show
                    <select
                      className="mx-1"
                      defaultValue={"10"}
                      // onChange={(e) => {
                      //   setEntriesPerPage(e.target.value);
                      //   fetchProdConfig(page, e.target.value);
                      // }}
                      onChange={(e) => {
                        dispatch({
                          type: "HANDLE_ACTION",
                          page: 1,
                          loading: state.loading,
                          error: state.error,
                          ScreenList: state.ScreenList,
                          itemsPerPage: e.target.value,
                          maxPage: state.maxPage,
                        })
                        setSrNo(0);
                      }

                      }
                    >
                      <option value={"10"}>10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </p>
                </div>
              </div> */}
              <div className="">
                {state.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : state.ScreenList && state.ScreenList.length > 0 ? (
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-sm"
                      width={"100%"}
                    >
                      <thead className="table-grey text-ceter inter">
                        <tr className="f-14">
                          <th scope="col" width="15%">
                            {"Sr. No"}
                          </th>
                          <th scope="col">{"Screen Name"}</th>
                          <th scope="col">{"Display Name"}</th>
                          <th scope="col" width="15%">
                            {"Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="inter">
                        {state.ScreenList.map((val, i) => (
                          <tr key={i}>
                            <td>{i + srNo + 1}</td>
                            <td>{val.screen_name}</td>
                            <td>
                              {val.display_name ? val.display_name : "NA"}
                            </td>
                            <td>
                              <Tooltip id={"edit-tooltip"} place="top" />
                              <div
                                data-tooltip-id={"edit-tooltip"}
                                data-tooltip-content="Edit Screen"
                                className="editIcon me-2"
                              >
                                <Edit2
                                  color="#FFFFFF"
                                  size={18}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    navigate("update-screen",{state:{...val, lable_list:lable_list}})
                                  }}
                                />
                              </div>

                              {/* <Tooltip id={"del-tooltip" + i} place="top" />
                                <div
                                  data-tooltip-id={"del-tooltip" + i}
                                  data-tooltip-content="Delete Screen"
                                  className="deleteIcon me-2"
                                  onClick={() => delScreen(val)}
                                >
                                  <Trash2 color="#FFFFFF" size={18} />
                                </div> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : apiBySearch ? (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"No Screen found !"}
                  </p>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Screen list is empty"}
                  </p>
                )}

                {state.loading ? null : (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className='mb-0'>{`Showing ${parseInt(srNo) + 1} to ${Math.min(parseInt(state.itemsPerPage) + parseInt(srNo), totalRecords)} of ${totalRecords} entries`}</p>
                    <Pagination>
                      <Pagination.Prev
                        disabled={(state.page+1) === 1 ? true : false}
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page - 1,
                            loading: state.loading,
                            error: state.error,
                            ScreenList: state.ScreenList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC - parseInt(state.itemsPerPage));
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{state.page+1}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                         ( state.page+1 )=== state.maxPage || state.maxPage === 0
                            ? true
                            : false
                        }
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page + 1,
                            loading: state.loading,
                            error: state.error,
                            ScreenList: state.ScreenList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC + parseInt(state.itemsPerPage));
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Screen;
