import {
  faAdd,
  faArrowLeft,
  faSquare,
  faCheck,
  faEdit,
  faTrashAlt,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import CreateModule from "./CreateModule";
import { delete_module_api, get_app, get_module_screen_api, get_screen_api, update_app, update_module_api } from "../../api";
import { toast, ToastContainer } from "react-toastify";
import SweetAlert from "sweetalert2";
import * as yup from "yup";

import EditModule from "./EditMoudle";
import AddScreenModule from "./AddScreenInModule";

const schema = yup
  .object()
  .shape({
    app_name: yup
      .string()
      .required("App name is required !")
  })
  .required();
export const EditApplication = () => {
  let navigate = useNavigate();
  let location = useLocation();
  console.log("locaitn", location);
  const [data, setData] = useState(location.state);
  const [screens, setScreens] = useState({ data: [], module_name: "" ,module_id:''});
  const [visible, setVisible] = useState(false);
  const [screen_list, setScreen_list] = useState({ data: [], loading: false });
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  const [module_data,setModule_data]=useState({});
  
  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  let { errors } = formState;
  useEffect(() => {
    fetchScreen();
 
      fetch_app();
    
  }, []);

  function fetchScreen() {
    setScreen_list({ data: [], loading: true });
    get_screen_api().then(
      (res) => {
        console.log("Screen res", res);

        setScreen_list({ data: res.data.data.response.data, loading: false });
      },
      (err) => {
        console.log("err", err);

        setScreen_list({ data: [], loading: false });
      }
    );
  }

  const fetch_app = () => {
    get_app({})
      .then((res) => {
        console.log("res", res.data.data.response);
        let obj = res.data.data.response.filter(
          (item) => item.app_id === location.state.app_id
        );

        console.log("obj", obj);
        if (
          obj[0].modules &&
          obj[0].modules.length > 0 &&
          obj[0].modules[0].module_screens.length > 0
        ) {
          let a = obj[0].modules[0];

          setScreens({ data: a.module_screens, module_name: a.module_name,module_id:a.module_id });
        }
        setData(obj[0]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const delModule = (val) => {
    //console.log("val", );
    SweetAlert.fire({
      title: "Are you sure ?",
      text: `Once deleted, you will not be able to recover this module ${val.module_name} !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          module_id: val.module_id,
        };
        delete_module_api(payload).then(
          (res) => {
            console.log("res", res);
            toast.success("Module deleted successfully !", {
              autoClose: 3000,
            });

            fetch_app();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };
  const onSubmit=(data)=>{


    if (data !== "") {
      const reqPayload = {
        app_name: data.app_name,
      };

      console.log("reqPayload", reqPayload);

      
      update_app( {app_id:location.state.app_id},reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Application updated Successfully !", {
              autoClose: 3000,
            });
            fetch_app();
          }
        },
        (err) => {
          console.log("err.response.status", err.response.status);
          if (err.response.status === 400) {
            console.log("err.response", err.response);
            toast.error('Something went wrong', {
              autoClose: 2000,
            });
            reset();
          }
        }
      )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }

  }
  const handle_screen_edit=async (val, obj)=>{
    console.log("dj",val,obj);
 
      let arr=obj.data.filter((item)=>item.screen_name!==val.screen_name);

      const reqPayload = {
        app_id: data.app_id,
        module_name: obj.module_name,
        screens:arr
      };

      console.log("reqPayload", reqPayload);

      
      update_module_api( {module_id:obj.module_id},reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Screen deleted successfully !", {
              autoClose: 3000,
            });
            fetch_app();
          }
        },
        (err) => {
          console.log("err.response.status", err.response.status);
          if (err.response.status === 400) {
            console.log("err.response", err.response);
            toast.error(err.response, {
              autoClose: 2000,
            });
          
          }
        }
      )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
 
   
  }
  return (
    <div className="col-12 mt-3">
      <ToastContainer />
      <CreateModule
        visible={visible}
        setVisible={setVisible}
        data={data}
        screen_list={screen_list.data}
        fetch_app={fetch_app}
      />
      <EditModule
        data={data}
       visible={visible2}
       setVisible={setVisible2}
      module_data={module_data}
       fetch_app={fetch_app}
       screen_list={screen_list.data}

      />
      <AddScreenModule
       data={data}
       visible={visible3}
       setVisible={setVisible3}
      module_data={screens}
       fetch_app={fetch_app}
       screen_list={screen_list.data}
      
      />
      <div className="card">
       
        
        <div className="card-header bg-grey d-flex align-items-center">
          <button className="btn btn-yellow" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} size="sm" />
          </button>
          <h3 className="m-2">Edit Application</h3>
        </div>
        <div className="card-body">
        <div className="col-4 mb-4">
        <form action="">
          <div className="form-group row mt-2">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-3"
            >
              Application
            </label>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control form-control-md"
                id="app_name"
                name="app_name"
                defaultValue={data.app_name&&data.app_name}
                {...register("app_name")}
              />
              <span className="text-danger">
                  {errors.app_name?.message}
                </span>
            </div>
            <div className="col-md-3">
            <button
             onClick={handleSubmit(onSubmit)}
            className=" btn btn-yellow inter"
          >
            {"Save"}
          </button>
          </div>
          </div>
        </form>
        
        </div>
          <div className="row">
            <div className="col-4">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="mb-3 inter-bold">Modules</h5>
                </div>

                <Tooltip id={"c-po-tooltip"} place="top" />
                <div
                  data-tooltip-id={"c-po-tooltip"}
                  data-tooltip-content="Create Module"
                  className="createIcon_custom cursor_pointer ms-2 position-relative"
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  <FontAwesomeIcon
                    color="#FFFFFF"
                    icon={faAdd}
                    size={"md"}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  />
                </div>
              </div>
              <div
                className="card  shadow-sm"
                style={{ border: "1px solid #edede9" }}
              >
                <div
                  className="card-body "
                  style={{ height: "500px", overflowY: "auto" }}
                >
                  {data?.modules.map((item, i) => {
                    return (
                      <div
                        className="card mb-2 cursor_pointer "
                        style={{ border: "none" }}
                      >
                        <div
                          className="ml-2 d-flex justify-content-between"
                          style={{ fontSize: "15px", color: "#343a40" }}
                        >
                          <div
                            className={item.module_name == screens.module_name&&`active_show`}
                            onClick={() => {
                              setScreens({
                                data: item.module_screens,
                                module_name: item.module_name,
                                module_id:item.module_id
                              });
                            }}
                          >
                            {item.module_name}
                            {item.module_name == screens.module_name ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="ms-2"
                                size="md"
                              />
                            ) : null}
                          </div>
                          <div>
                            <FontAwesomeIcon
                              icon={faEdit}
                              size="sm"
                              color="#817f75"
                              className="ms-2"
                              onClick={() => {
                                setModule_data(item);
                                setVisible2(true);
                              }}
                            />
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              size="sm"
                              color="#817f75"
                              className="ms-2 me-2"
                              onClick={() => delModule(item)}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div class="col-8">
              <div className="d-flex justify-content-between">
                <div>
                  <h4 className="mb-3 inter-bold">Screens</h4>
                </div>

                <Tooltip id={"c-po-tooltip"} place="top" />
                <div
                  data-tooltip-id={"c-po-tooltip"}
                  data-tooltip-content="Create Screen"
                  className="createIcon_custom cursor_pointer ms-2 position-relative"
                  onClick={() => {
                    setVisible3(true);
                  }}
                >
                  <FontAwesomeIcon
                    color="#FFFFFF"
                    icon={faAdd}
                    size={"md"}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  />
                </div>
              </div>
              <div
                className="card shadow-sm"
                style={{ border: "1px solid #edede9" }}
              >
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0 inter-bold">{`Module Name: ${screens.module_name}`}</h5>
                  </div>

                

                  <div className="pill-container">
                  {screens.data.length > 0 &&
                    screens.data.map((item,ind) => {
                      return (
                        
                        
                      <div className="custom-pill " key={ind}>
                        {item.screen_name}
 
                        <FontAwesomeIcon
                          style={{
                            color: "#fff",
                            cursor: "pointer",
                            marginLeft: "20px",
                          }}
                          icon={faClose}
                          size="lg"
                          onClick={() => {
                           
                            handle_screen_edit(item, screens)
                            
                          }}
                        />
                      </div>
                    
              
                    );
                    })
                  
                    
                  }</div>
                  {screens.data.length==0&&
                   
                    <div
                      className="alert alert-danger mb-0 text-center"
                      role="alert"
                    >
                      {"No Screens present !"}
                    </div>
                  
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
