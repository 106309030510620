import React, { useState, useEffect, useReducer } from "react";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBan } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination } from "react-bootstrap";
import { Edit2, Trash2, PlusSquare } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";

import { delete_client_api, get_client_api, get_role_api } from "../../api";
import { calMaxPage } from "../../common/Functions/CommonFunctions";
import { useNavigate } from "react-router-dom";
import CampaignForClient from "./CampaignForClient";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("client name is required !"),
    app: yup
      .array()
      .required("Please select application !")
      .min(1, "Please select application !"),
  })
  .required();

function Client() {
  const [visible, setVisible] = useState(false);
  const [selClient, setSelClient] = useState({});

  const [apiBySearch, setApiBySearch] = useState(false);
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [roleList, setRoleList] = useState({ data: [], loading: false });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook
  let navigate = useNavigate();
  let { errors } = formState;

  const initialState = {
    loading: false,
    error: "",
    clientList: [],
    itemsPerPage: 10,
    page: 0,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          clientList: action.clientList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("state", state);
    dispatch({
      type: "HANDLE_ACTION",
      page: state.page,
      loading: true,
      error: state.error,
      clientList: state.clientList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchclient();
    fetchRoles();
  }, [state.page, state.itemsPerPage]);

  function fetchclient() {
    dispatch({
      type: "HANDLE_ACTION",
      page: state.page,
      loading: true,
      error: state.error,
      clientList: state.clientList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      offset: state.page,
      limit: state.itemsPerPage,
    };

    //console.log("reqPayload", reqPayload);

    get_client_api(reqPayload).then(
      (res) => {
        console.log("client res", res);
        console.log(
          "calMaxPage(res.data.data.total)",
          // calMaxPage(res.data.total)
          calMaxPage(res.data.data.response.total)
        );
        setTotalRecords(res.data.data.response.total);

        dispatch({
          type: "HANDLE_ACTION",
          page: state.page,
          loading: false,
          error: state.error,
          clientList: res.data.data.response.data,
          itemsPerPage: state.itemsPerPage,
          maxPage: calMaxPage(res.data.data.response.total),
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 5000 });
        }
      }
    );
  }
  function fetchRoles() {
    setRoleList({ ...roleList, data: [], loading: true });

    get_role_api().then(
      (res) => {
        console.log("res", res);
        setRoleList({
          ...roleList,
          data: res.data.data.response,
          loading: false,
        });
      },
      (err) => {
        setRoleList({ ...roleList, data: [], loading: false });
        console.log("err", err);
      }
    );
  }

  const delclient = (val) => {
    //console.log("val", );
    SweetAlert.fire({
      title: "Are you sure ?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          client_id: val.client_id,
        };
        delete_client_api(payload).then(
          (res) => {
            console.log("res", res);
            toast.success("client deleted successfully !", {
              autoClose: 3000,
            });

            fetchclient();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="min-vh-100" id="root_div_main">
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 inter-bold"
                style={{ fontSize: "14px" }}
              >
                Search
              </label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search by name"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      //  fetchclient("search", e.target.value);
                      setApiBySearch(true);
                    } else {
                      //  fetchclient();
                    }
                  }}
                />
              </div>
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button className="btn btn-sm btn-yellow">
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                Clear Filter
              </button>
              {/* <button
                          className="btn btn-sm btn-grey ms-0 ms-3 px-4"
                      >
                          <FontAwesomeIcon style={{ color: "#fff" }} icon={faSearch} size="sm" />{" "}
                          Search
                      </button> */}
            </div>
          </div>
        </div>

        {visible && <CampaignForClient visible={visible} setVisible={setVisible} selClient={selClient} />}


        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <label className="page-heading">{"Client"}</label>
            <div>
              {/* <button
                className="btn btn-grey px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faFilter}
                  size="sm"
                />
                Filter Menu
              </button> */}

              <button
                className="btn btn-pink px-4"
                onClick={() => {
                  navigate("create-client", { state: roleList.data });
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                {"Add client"}
              </button>
            </div>
          </div>
          <div className="card border-0 mt-3">
            <div className="card-body">
              {/* <div className="d-flex justify-content-start mb-3 me-3">
                <div>
                  <p className="mb-0">
                    Show
                    <select
                      className="mx-1"
                      defaultValue={"10"}
                      // onChange={(e) => {
                      //   setEntriesPerPage(e.target.value);
                      //   fetchProdConfig(page, e.target.value);
                      // }}
                      onChange={(e) => {
                        dispatch({
                          type: "HANDLE_ACTION",
                          page: 1,
                          loading: state.loading,
                          error: state.error,
                          clientList: state.clientList,
                          itemsPerPage: e.target.value,
                          maxPage: state.maxPage,
                        })
                        setSrNo(0);
                      }

                      }
                    >
                      <option value={"10"}>10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </p>
                </div>
              </div> */}
              <div className="">
                {state.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : state.clientList && state.clientList.length > 0 ? (
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-sm"
                      width={"100%"}
                    >
                      <thead className="table-grey text-ceter inter">
                        <tr className="f-14 text-center">
                          <th scope="col" width="15%">
                            {"Sr. No"}
                          </th>
                          <th scope="col">{"Client Name"}</th>
                          <th scope="col">{"Contact No."}</th>
                          <th scope="col">{"Email"}</th>
                          <th scope="col">{"Organization"}</th>
                          <th scope="col">{"GST"}</th>
                          <th scope="col">{"PAN"}</th>
                          <th scope="col" width="15%">
                            {"Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="inter">
                        {state.clientList.map((val, i) => (
                          <tr key={i} className="text-center">
                            <td>{i + srNo + 1}</td>
                            <td>{val.company_name}</td>
                            <td>{val.contact_no}</td>
                            <td>{val.email}</td>
                            <td>{val.organization}</td>
                            <td>{val.gst_no}</td>
                            <td>{val.pan_no}</td>
                            <td>
                              <Tooltip id={"edit-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"edit-tooltip" + i}
                                data-tooltip-content="Edit Client"
                                onClick={() =>
                                  navigate("/update-client", {
                                    state: { ...val, roleList: roleList.data },
                                  })
                                }
                                className="editIcon m-1 cursor_pointer"
                              >
                                <Edit2 color="#FFFFFF" size={18} />
                              </div>

                              <Tooltip id={"del-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"del-tooltip" + i}
                                data-tooltip-content="Delete client"
                                className="deleteIcon m-1"
                                onClick={() => delclient(val)}
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>

                              <Tooltip id={"add-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"add-tooltip" + i}
                                data-tooltip-content="Add Campaign"
                                className="downloadIcon m-1"
                                onClick={() => {
                                  console.log("aaklsd");
                                  setVisible(true);
                                  setSelClient(val);
                                }}
                              >
                                <PlusSquare color="#FFFFFF" size={18} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : apiBySearch ? (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"No client found !"}
                  </p>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"client list is empty"}
                  </p>
                )}

                {state.loading ? null : (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(state.itemsPerPage) + parseInt(srNo),
                        totalRecords
                      )} of ${totalRecords} entries`}</p>
                    <Pagination>
                      <Pagination.Prev
                        disabled={state.page + 1 === 1 ? true : false}
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page - 1,
                            loading: state.loading,
                            error: state.error,
                            clientList: state.clientList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo(
                            (prevC) => prevC - parseInt(state.itemsPerPage)
                          );
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{state.page + 1}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          state.page + 1 === state.maxPage ||
                            state.maxPage === 0
                            ? true
                            : false
                        }
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page + 1,
                            loading: state.loading,
                            error: state.error,
                            clientList: state.clientList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo(
                            (prevC) => prevC + parseInt(state.itemsPerPage)
                          );
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Client;
