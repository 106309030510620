import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { update_inspection_type } from "../../../api";

const schema = yup
  .object()
  .shape({
    inspection_name: yup
      .string()
      .trim()
      .required("Inspection type is required!"),
  })
  .required();

function EditInspectionType(props) {
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        inspection_name: data.inspection_name.trim(),
      };

      update_inspection_type(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Inspection type updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchInspection_Type(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };
  return (
    <React.Fragment>
    <Tooltip id={"edit-tooltip"} place="top" />
      <div
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={props.constLabel?.lbl_edit_inspection_type ? props.constLabel?.lbl_edit_inspection_type : "Edit Inspection Status"} 
        className="editIcon me-2">
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
    <Modal show={visible} onHide={() => {setVisible(false); reset();}} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{props.constLabel?.lbl_update_inspection_type ? props.constLabel?.lbl_update_inspection_type : "Update Inspection Type"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
          <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {props.constLabel?.lbl_inspection_type ? props.constLabel?.lbl_inspection_type : "Inspection Type"}
                  <span className="text-danger"> *</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text" 
                    className={
                        errors.inspection_name
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                    id="inspection_name"
                    name="inspection_name"
                    placeholder="Enter inspection type"
                    defaultValue={props.data?.inspection_name}
                    {...register("inspection_name")}
                  />
                  <span className="text-danger err-msg">
                    {errors.inspection_name?.message}
                  </span>
                </div>
              </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>

  </React.Fragment>
  );
}

export default EditInspectionType;
