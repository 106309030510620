import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updatehsn_master,  } from "../../../api";

const schema = yup
  .object()
  .shape({
    // hsn_sac_code: yup.string().trim().required("HSN Sac code is required!"),
    gst_rate: yup.string().trim().required("GST rate is required!"),
    // description: yup.string().trim().required("Description is required!"),
  })
  .required();

function EditHSNMaster(props) {
    const [visible, setVisible] = useState(false);

    const { register, handleSubmit, formState, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        }); // initialise the hook
    
        let { errors } = formState;
    
        const onSubmit = (data) => {
            
        console.log("data",props.data)
            if (data !== "") {
              const reqPayload = {
                uuid:props.data.uuid,
                hsn_sac_code:props.data?.hsn_sac_code,
                gst_rate: data.gst_rate.trim(),
                description: props.data.description,
              };
        
              console.log("reqPayload",reqPayload)
        
     updatehsn_master(reqPayload).then(
                  (res) => {
                    
                    console.log("ers",res)
                    
                      toast.success("HSN updated successfully !", {
                        autoClose: 3000,
                      });
                      setVisible(false);
                      props.fetch_HSN(props.page,props.entriesPerPage);
                    
                  },
                  (err) => {
                    
                    
                      
                      toast.error(err.response.data.message, {
                        autoClose: 2000,
                      });
                      // reset();
                    
                  }
                )
            } else {
              errors.showMessages();
            }
          };

  return (
    <React.Fragment>
    <Tooltip id={"edit-tooltip"} place="top" />
      <div
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={ "Edit HSN Master"} 
        className="editIcon me-2">
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
    <Modal show={visible} onHide={() => {setVisible(false); reset();}} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{ "Update HSN Master"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
          <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  { "HSN Sac Code"}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text" 
                    className={
                        errors.hsn_sac_code
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                    id="hsn_sac_code"
                    name="hsn_sac_code"
                    placeholder="Enter HSN SAC code"
                    disabled={true}
                    defaultValue={props.data?.hsn_sac_code}
                    {...register("hsn_sac_code")}
                  />
                  {/* <span className="text-danger err-msg">
                    {errors.hsn_sac_code?.message}
                  </span> */}
                </div>
              </div>

              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  { "Description"}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <textarea
                    type="text" 
                    rows={2}
                    className={
                        errors.description
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                    id="description"
                    name="description"
                    placeholder="Enter description"
                    disabled={true}
                    defaultValue={props.data?.description}
                    {...register("description")}
                  />
                  {/* <span className="text-danger err-msg">
                    {errors.description?.message}
                  </span> */}
                </div>
              </div>
              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  { "GST Rate"}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text" 
                    className={
                        errors.gst_rate
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                    id="gst_rate"
                    name="gst_rate"
                    placeholder="Enter gst rate"
                    defaultValue={props.data?.gst_rate}
                    {...register("gst_rate")}
                  />
                  <span className="text-danger err-msg">
                    {errors.gst_rate?.message}
                  </span>
                </div>
              </div>
            
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            { "Save"}
          </button>
        </Modal.Footer>
      </Modal>

  </React.Fragment>
  )
}

export default EditHSNMaster;