import React from "react";
import Navbar from '../Navbar/Navbar'
import AppContent from "./AppContent";
import Footer from "../FooterSection/Footer"

function DefaultLayout() {
  return (
    <React.Fragment>
      <div className="min-vh-100" id="root_div">
        {/* <Sidebar /> */}
        <Navbar />
        <div id="root_body" >
          {/* <div id="root_body" style={{ marginLeft: "64px" }}> */}
          <AppContent />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default DefaultLayout;
