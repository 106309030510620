import { faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Edit2, Trash2 } from "react-feather";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";
import { Addlabel } from "./Addlabel";
import { save_screen_api, update_screen_api } from "../../api";
import { Addtablelable } from "./Addtablelable";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Screen name is required !"),
  })
  .required();

export const UpdateScreen = () => {
    const location =useLocation();
   // console.log("location",location)
   const navigate=useNavigate()
  const [labels, setLabels] = useState({});
  const [table_label, setTable_lables] = useState([]);
  const [editedKey, setEditedKey] = useState("");
  const [editedValue, setEditedValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [editedKey2, setEditedKey2] = useState("");
  const [editedValue2, setEditedValue2] = useState({});
  const [visible2, setVisible2] = useState(false);
  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;


  const handleEdit = (key) => {
    setEditedKey(key);
    setEditedValue(labels[key]);
  };

  const handleDelete = (key) => {
    const updatedLabels = { ...labels };
    delete updatedLabels[key];
    setLabels(updatedLabels);
  };

  const handleInputChange = (event) => {
    setEditedValue(event.target.value);
  };

  const handleUpdate = (key) => {
    const updatedLabels = { ...labels, [key]: editedValue };
    setLabels(updatedLabels);
    setEditedKey("");
    setEditedValue("");
  };



  const handleEdit2 = (ind) => {
    console.log("ind", ind);
    setEditedKey2(ind);
    setEditedValue2(table_label[ind]);
  };
  const handleDelete2 = (ind) => {
    table_label.splice(ind, 1);

    setTable_lables([...table_label]);
  };

  const handleInputChange2 = (event) => {
    console.log("key", event.target.checked);

    if (event.target.type == "text") {
      let obj = { ...editedValue2, value: event.target.value };
      setEditedValue2(obj);
    } else {
      let obj = { ...editedValue2, flag: event.target.checked };
      setEditedValue2(obj);
    }

    // setEditedValue2(event.target.value);
  };

  const handleUpdate2 = (ind) => {
    console.log("key", ind);
    let arr = [...table_label];
    console.log("edi", editedValue2);
    arr[ind] = editedValue2;

    setTable_lables(arr);
    // setLabels(updatedLabels);
    setEditedKey2("");
    setEditedValue2([]);
  };

  useEffect(()=>{
    console.log("location",location.state);

     if(location.state.labelList!==null&&location.state.labelList!==undefined&&Object.keys(location.state?.labelList).length>0){
      console.log("if");
      let obj={};
        if(location.state.labelList?.tbl_column?.length>0){
            let arr=location.state.labelList?.tbl_column;
            console.log("arr",arr)  
             let t_arr=arr.map((item)=>{
                  let col_obj={
                    key:"label",
                    value:item.label,
                    flag:item.flag
                  }
                  return col_obj
             })
           console.log("t_arr",t_arr)  
            setTable_lables(t_arr) 
            obj={...location.state.labelList};
            delete obj.tbl_column; 
            setLabels(obj)
        }else{
          console.log("else")
          obj={...location.state.labelList};
          if(obj.tbl_column){
            delete obj.tbl_column;
          }
          setLabels(obj);
          setTable_lables([])
        }
      
     }else{
      let obj_arr=location.state.lable_list.filter((item)=>item.screen_name==location.state.screen_name);
       console.log("obj_arr",obj_arr)
      if(obj_arr.length>0){
        console.log("i")
        if(obj_arr[0].labelList?.tbl_column?.length>0){
          let obj={...obj_arr[0].labelList};
          console.log("j")
          let arr=obj.tbl_column;
          console.log("arr",arr)  
           let t_arr=arr.map((item)=>{
                let col_obj={
                  key:"label",
                  value:item.label,
                  flag:item.flag
                }
                return col_obj
           })
         console.log("t_arr",t_arr)  
          setTable_lables(t_arr)
          delete obj.tbl_column;
          setLabels(obj);
        }
        else{
          console.log("else")
        let  obj={...obj_arr[0].labelList};
          if(obj.tbl_column){
            delete obj.tbl_column;
          }
          setLabels(obj);
          setTable_lables([])
        }
        
      }
     }

   
     
      
},[])
  const onSubmit = (data) => {
    console.log("submitted Data", data);
    console.log("Updated Labels:", labels);
    
    console.log("table labes:", table_label);
    let arr = [];
    if (table_label.length > 0) {
      arr = table_label.map((item) => {
        let obj = {
          [`${item.key}`]: item.value,
          flag: item.flag,
        };
        return obj;
      });
      console.log("arr", arr);
    }

    if (data !== "") {
        const reqPayload = {
            screen_name: data.name,
            display_name: data.display_name,
            labelList: arr.length>0?{ ...labels, tbl_column: arr }:{...labels},
          };

      console.log("reqPayload", reqPayload);

      update_screen_api( {screen_id:location.state.screen_id},reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Screen updated Successfully !", {
              autoClose: 2000,
            });
            setTimeout(()=>{

              navigate("/screen")
            },2200)
           
          }
        },
        (err) => {
          console.log("err.response.status", err.response.status);
          if (err.response.status === 400) {
            console.log("err.response", err.response);
            toast.error(err.response, {
              autoClose: 2000,
            });
            reset();
          }
        }
      )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <div className="create_role mb-4">
      <Addlabel
        visible={visible}
        setVisible={setVisible}
        labels={labels}
        setLabels={setLabels}
      />
       <Addtablelable
        visible={visible2}
        setVisible={setVisible2}
        table_label={table_label}
        setTable_lables={setTable_lables}
      />
      {/* <label className="page-heading">{"Add Screen"}</label> */}

      <div className="card mt-3">
        <div className="card-header bg-grey d-flex align-items-center">
        <button className="btn btn-yellow" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} size="sm" />
          </button>

          <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
           Update Screen
          </p>
        </div>

        <form action="">
          <div className="row col-12 m-2">
            <div className="col-6">
              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-4"
                >
                  Screen Name
                  <span className="text-danger">
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="name"
                    name="name"
                    placeholder="Enter Screen name"
                    defaultValue={location.state?.screen_name&&location.state?.screen_name}
                    {...register("name")}
                  />
                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-4"
                >
                  Display Name
                </label>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="display_name"
                    name="display_name"
                    defaultValue={location.state?.display_name&&location.state?.display_name}
                    placeholder="Enter display name"
                    {...register("display_name")}
                  />
                </div>
              </div>
            </div>
          </div>
         
        </form>
        
        <div className="card-body">
        <div>
            <hr />
            <div className="d-flex justify-content-between">
              <p className="mb-2 ms-3" style={{ fontSize: "24px" }}>
                Screen labels
              </p>

              <button
                className="btn btn-pink px-4 me-3 float-end"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                {"Add Label"}
              </button>
            </div>

            <hr />
            <table className="table table-striped table-sm" width={"100%"}>
              <thead className="table-grey text-ceter inter">
                <tr>
                  <th>Screen Label Key</th>
                  <th>Screen Lable Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(labels).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>
                      {editedKey === key ? (
                        <input
                          value={editedValue}
                          onChange={handleInputChange}
                        />
                      ) : (
                        value
                      )}
                    </td>
                    <td>
                      {editedKey === key ? (
                        <button
                          className="btn btn-yellow px-3 btn-sm"
                          onClick={() => handleUpdate(key)}
                        >
                          Update
                        </button>
                      ) : (
                        <React.Fragment>
                          <div className="editIcon me-2">
                            <Edit2
                              color="#FFFFFF"
                              size={18}
                              className="cursor-pointer"
                              onClick={() => handleEdit(key)}
                            />
                          </div>

                          <div
                            className="deleteIcon me-2"
                            onClick={() => handleDelete(key)}
                          >
                            <Trash2 color="#FFFFFF" size={18} />
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <button onClick={handleAddRow}>Add Row</button> */}
          </div>
          <div className="mt-5">
            <hr />
            <div className="d-flex justify-content-between">
              <p className="mb-2 ms-3" style={{ fontSize: "24px" }}>
                Table Columns
              </p>
              <button
                className="btn btn-pink px-4 me-3 float-end"
                onClick={() => {
                  setVisible2(true);
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                {"Add Column"}
              </button>
            </div>

            <hr />
            <table className="table table-striped table-sm" width={"100%"}>
              <thead className="table-grey text-ceter inter">
                <tr>
                  {/* <th>Table Label Key</th> */}
                  <th>Table Column Name</th>
                  <th>Show/Hide</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {table_label.map((item, ind) => (
                  <tr key={`${item.key}${ind}`}>
                    {/* <td>{item.key}</td> */}
                    <td>
                      {editedKey2 === ind ? (
                        <input
                          value={editedValue2.value}
                          onChange={(e) => handleInputChange2(e)}
                        />
                      ) : (
                        item.value
                      )}
                    </td>
                    <td>
                      {editedKey2 === ind ? (
                        <div className="form-check form-switch ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={editedValue2.flag}
                            onChange={(e) => handleInputChange2(e)}
                          />
                        </div>
                      ) : item.flag ? (
                        "Show"
                      ) : (
                        "Hide"
                      )}
                    </td>
                    <td>
                      {editedKey2 === ind ? (
                        <button
                          className="btn btn-yellow px-3 btn-sm"
                          onClick={() => handleUpdate2(ind)}
                        >
                          Update
                        </button>
                      ) : (
                        <React.Fragment>
                          <div className="editIcon me-2">
                            <Edit2
                              color="#FFFFFF"
                              size={18}
                              className="cursor-pointer"
                              onClick={() => handleEdit2(ind)}
                            />
                          </div>

                          <div
                            className="deleteIcon me-2"
                            onClick={() => handleDelete2(ind)}
                          >
                            <Trash2 color="#FFFFFF" size={18} />
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="card-footer py-3">
          <button
            className="btn btn-yellow px-4 float-end"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
