import { parse, format } from 'date-fns'

export const convertDateYYMMDD = (d, divider) => {
  var weekDate = new Date(d)
  let date = weekDate.getDate()
  let month = weekDate.getMonth() + 1
  const year = weekDate.getUTCFullYear()

  if (month < 10) {
    month = '0' + month
  }
  if (date < 10) {
    date = '0' + date
  }

  return year + divider + month + divider + date
}
export const convertDateYYMMDDUsingLib = (d) => {
  // Parse the date string into a Date object
  const parsedDate = parse(d, 'dd-MM-yyyy', new Date())

  // Format the Date object into the desired format
  const formattedDate = format(parsedDate, 'yyyy-MM-dd')

  // console.log(formattedDate); // Output: "2023-02-09"
  return formattedDate
}

export const convertDate = (d, divider) => {
  var weekDate = new Date(d);
  let date = weekDate.getDate();
  let month = weekDate.getMonth() + 1;
  const year = weekDate.getUTCFullYear();

  // const formattedDate = (date < 10 ? '0' : '') + date;
  // const formattedMonth = (month < 10 ? '0' : '') + month;

  if (month < 10) {
    month = '0' + month
  }
  if (date < 10) {
    date = '0' + date
  }
  return date + divider + month + divider + year;
}

export function convertDateFormat(inputDate) {
  if (inputDate !== undefined) {
    const parts = inputDate.split('-'); // Split the input date
    const year = parts[2];
    const month = parts[1].length === 1 ? '0' + parts[1] : parts[1]; // Add leading zero if needed
    const day = parts[0].length === 1 ? '0' + parts[0] : parts[0]; // Add leading zero if needed

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
}

export function convertDateYYMMDDHHMM(date) {
  if (date !== undefined) {
    // Extract the year, month, day, hour, and minute
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
    return formattedDate;
  }
}

export const calMaxPage = (counts) => {
  const maxPage = Math.floor(counts / 10)

  if (counts % 10 === 0) {
    return maxPage
  } else {
    return maxPage + 1
  }
}

export const calMaxPage_new = (counts,entriesPerPage) => {
  //console.log("enter",entriesPerPage)
  const maxPage = Math.ceil(counts / entriesPerPage)

 // console.log("maxfda",maxPage);
  return maxPage; 
}

export function timeDuration(startTime, endTime) {
  const start = startTime.split(':').map(Number)
  const end = endTime.split(':').map(Number)
  const startInMinutes = start[0] * 60 + start[1]
  const endInMinutes = end[0] * 60 + end[1]
  const duration = endInMinutes - startInMinutes
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`
}

export function timeDuration2(start, end) {
  const date1 = start
  const date2 = end

  const durationInMs = date2 - date1
  const durationInMinutes = durationInMs / (1000 * 60)
  const hours = Math.floor(durationInMinutes / 60)
  const minutes = durationInMinutes % 60
  const durationFormatted = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`

  // console.log(durationFormatted); // Output: 01:30
  return durationFormatted
}

export async function downloadAsJSON(val) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  console.log('val', val)
  val.customerId = userInfo.user_id

  if (Object.keys(val).length > 0) {
    const json = await JSON.stringify(val)
    // console.log('json', json)
    const blob = new Blob([json], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const fileName = val.name + '_' + val.vesselname + '.json'
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    URL.revokeObjectURL(url)
  } else {
    return null
  }
}


export function splitStringFromQuestionMark(inputString) {
  const questionMarkIndex = inputString.indexOf('?');
  if (questionMarkIndex !== -1) {
    const substringAfterQuestionMark = inputString.substring(questionMarkIndex);
    return substringAfterQuestionMark;
  } else {
    return inputString;
  }
}


export const generateAndDownloadCSV = (jsonData) => {
  if (!Array.isArray(jsonData) || jsonData.length === 0) return;

  const csvRows = jsonData.map((data) =>
    Object.values(data)
      .map((value) => `"${value}"`)
      .join(",")
  );

  const csvContent = csvRows.join("\n");

  const blob = new Blob([csvContent], { type: "text/csv" });

  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = "Operation_category.csv";

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
};


export function convertImageTobase64(file) {
  const reader = new FileReader();

  reader.onload = function (e) {
    const base64String = e.target.result;


    console.log("base64String", base64String);
    return base64String
  };

}


export function generateRandomString(length) {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset.charAt(randomIndex);
  }

  return randomString;
}








export var settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};


export function convertUrl(url) {
  // console.log("url", url);
  var result;

  if (url !== null || url !== undefined) {
    result = url.split("?");
    // console.log("result",result);
  }
  // console.log("result[0]",result[0]);
  return result[0];
}













export function amountInWords(amount) {
  const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const thousands = ["", "Thousand", "Million", "Billion"];

  function toWords(num) {
    if (num === 0) return "Zero";
    if (num < 10) return units[num];
    if (num < 20) return teens[num - 10];
    if (num < 100) return tens[Math.floor(num / 10)] + " " + toWords(num % 10);
    return units[Math.floor(num / 100)] + " Hundred " + toWords(num % 100);
  }

  function groupToWords(num, idx) {
    if (num === 0) return "";
    if (num < 1000) return toWords(num) + " " + thousands[idx];
    return toWords(Math.floor(num / 1000)) + " " + thousands[idx] + " " + groupToWords(num % 1000, idx + 1);
  }

  return groupToWords(amount, 0);
}

