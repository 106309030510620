import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "react-tooltip";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import {
    get_campaign_master_api, get_channel_master_api,
    link_client_campaign_api, get_client_campaign_api, update_client_campaign_api,
    delete_client_campaign_api
} from "../../api/index";
import SweetAlert from "sweetalert2";
import {
    faAdd,
    faArrowLeft,
    faArrowRight,
    faClose,
    faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trash2 } from "react-feather";


const schema = yup
    .object()
    .shape({
        items: yup.array().of(
            yup.object().shape({
                campaign: yup.array().
                    min(1,"Please select campaign !" )
                    .required("Campaign is required !"),
                channel: yup.array().
                    min(1,"Please select channel !")
                    .required("Client is required !"),
            })
        ),
    })
    .required();

function CampaignForClient({ visible, setVisible, selClient }) {


    const [campaignList, setCampaignList] = useState({ loading: true, data: [] });
    const [channelList, setChannelList] = useState({ loading: true, data: [] });
    const [action, setAction] = useState({ act: "create", data: [], loading: true });
    const [linkcampaignList,setLinkcampaignList]=useState([])
    const { register, handleSubmit, formState, control, reset, setValue, watch } =
        useForm({
            resolver: yupResolver(schema),
            mode: "onChange",
            defaultValues: {
                items: [{}],
            },
        }); // initialise the hook

    let { errors } = formState;
    const { fields, append, remove } = useFieldArray({ control, name: "items", });

    useEffect(() => {
        if (visible) {
            get_campaign_master_api().then((res) => {
                console.log("res", res);
                setCampaignList({ ...campaignList, data: res.data.data });
            }, (err) => {
                console.log("err", err);
                setCampaignList({ ...campaignList, data: [], loading: false });
            });


            get_channel_master_api().then(
                (res) => {
                    console.log("res", res.data.data);
                    setChannelList({ ...channelList, data: res.data.data });
                },
                (err) => {
                    console.log("err", err);
                    setChannelList({ ...channelList, data: [], loading: false });
                }
            );

           // setAction({ ...action, act: "update", data: [], loading: true });
           getlinked_campaign_list()
        }
    }, [visible]);

    function getlinked_campaign_list(){
        get_client_campaign_api({ client_id: selClient.client_id }).then((res) => {
            console.log("res", res);

            if (res.data.data.length > 0) {
                setAction({ ...action, act: "update", data: res.data.data, loading: false });
                let tempList = [];

                for (let i = 0; i < res.data.data.length; i++) {
                    tempList.push({ campaign: [res.data.data[i].cammp], channel: [res.data.data[i].channels] ,uuid:res.data.data[i].uuid});
                }
                console.log("tempList", tempList);
                setLinkcampaignList(tempList)
                reset({ items: tempList });
                // reset({ items: [{}, {}] });
            } else {
                setAction({ ...action, loading: false });
            }
        }, (err) => {
            console.log("err", err);

        });
    }


    const onSubmit = (data) => {
        console.log("submitted Data", data);
        console.log("selClient", selClient);

        if (data !== "") {
            const payload = {
                client_id: selClient.client_id,
                campaign: data.items.map((val) => {
                    let obj = {};
                    obj.campaign_id = val.campaign[0].uuid;
                    obj.channel = {
                        uuid: val.channel[0].uuid,
                        channel_description: val.channel[0].channel_description,
                        channel_domain_url: val.channel[0].channel_domain_url
                    };
                    obj.campaign_created_date = val.campaign[0].campaign_created_date;
                    if(val.uuid){

                        obj.uuid = val.uuid
                    }
                    console.log("val.uuid",val.uuid)
                    return obj
                })
            }


          
            console.log("payload", payload);
            
            
             
            if (action.act == "create") {
              payload.campaign.forEach((item)=>{
               delete item.uuid
              })
              console.log("payload", payload);
            
                link_client_campaign_api(payload).then((res) => {
                    console.log("res", res);
                    toast.success("Campaign and channel linked with client successfully !", {
                        autoClose: 3000,
                    });
                    setVisible(false)
                }, (err) => {

                    console.log("err", err);
                });
            } else {
        //  console.log("payload", payload);
        //  console.log("linkcampaignList",linkcampaignList)
    //    let arr=  payload.campaign.map((obj1, index) => {
    //     // console.log("obj1",obj1)
    //         let matchFound = linkcampaignList.some(obj2 => obj2.campaign[0].uuid === obj1.uuid);
    //         // console.log("matchFound",matchFound)
    //         if (!matchFound) {
    //           delete obj1.uuid
    //         }
    //         return obj1
    //     });
    //      payload.campaign=arr; 
        //  console.log("arr", arr);
          console.log("payload", payload);
         
                update_client_campaign_api(payload).then((res) => {
                    console.log("res", res);
                    toast.success("Campaign and channel updated successfully !", {
                        autoClose: 3000,
                    });
                    setVisible(false)
                }, (err) => {

                    console.log("err", err);
                });
            }
        } else {
            errors.showMessages();
        }
    };


    function deletelinkedcampaign(id) {
        SweetAlert.fire({
            title: "Are you sure to delete this linked campaign ? \n",            
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                // Firing delete api
              
                delete_client_campaign_api({ uuid: id }).then(
                        (res) => {
                            toast.success("Linked campaign deleted successfully !", {
                                autoClose: 1000,
                            });
                            getlinked_campaign_list()
                        },
                        (err) => {
                            toast.error("Something went wrong ");
                        }
                    );
                 
            }
        });
    }

    return (
        <React.Fragment>
            <ToastContainer />

            <Modal show={visible} onHide={() => setVisible(false)} size={"lg"} backdrop="static">
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>{"Link Campaign"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {action.act == "update" && action.loading ?
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <table className="table table-responsive table-striped">
                            <thead className="table-grey inter-bold">
                                <tr>
                                    <th scope="col">
                                        Select Campaign
                                    </th>
                                    <th scope="col">
                                        Select Channel
                                    </th>
                                    <th scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {fields.map(({ id }, index) => (
                                    <tr key={id}>
                                        <td style={{ verticalAlign: "top" }}>
                                            <Controller
                                                name={`items[${index}].campaign`}
                                                control={control}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Typeahead
                                                        id="basic-typeahead"
                                                        labelKey="campaign_name"
                                                        multiple={false}
                                                        options={campaignList.data}
                                                        loading={campaignList.loading}
                                                        placeholder="Select Campaign"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        isInvalid={errors.items?.[index]?.campaign ? true : false}
                                                    />
                                                )}
                                            />

                                            {errors.items?.[index]?.campaign && (
                                                <span className="text-danger err-msg">
                                                    {errors.items?.[index]?.campaign.message}
                                                </span>
                                            )}

                                            {index + 1 == fields.length ?
                                                <div>
                                                    <button
                                                        style={{ fontSize: "16px" }}
                                                        className="btn btn-sm btn-pink px-2 mt-2"
                                                        onClick={() => {
                                                            append({});
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            style={{
                                                                color: "#ffffff",
                                                            }}
                                                            icon={faAdd}
                                                            size="sm"
                                                        />{" "}Add
                                                    </button>
                                                </div>
                                                : null}

                                        </td>

                                        <td style={{ verticalAlign: "top" }}>
                                            <Controller
                                                name={`items[${index}].channel`}
                                                control={control}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Typeahead
                                                        id="basic-typeahead"
                                                        labelKey="channel_domain_url"
                                                        multiple={false}
                                                        options={channelList.data}
                                                        loading={channelList.loading}
                                                        placeholder="Select Channel"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        isInvalid={errors.items?.[index]?.channel ? true : false}
                                                    />
                                                )}
                                            />

                                            {errors.items?.[index]?.channel && (
                                                <span className="text-danger err-msg">
                                                    {errors.items?.[index]?.channel.message}
                                                </span>
                                            )}

                                        </td>

                                        <td
                                            style={{
                                                verticalAlign: "top",
                                                width: "10%",
                                            }}
                                        >
                                            <div
                                                className="deleteIcon cursor_pointer"
                                                onClick={
                                                   
                                                
                                                    index > 0
                                                        ? () => {
                                                            // console.log("item",fields);
                                                             if(fields[index].uuid){
                                                                // console.log("uuid",fields[index].uuid)
                                                                 deletelinkedcampaign(fields[index].uuid);
                                                             }else{

                                                                 remove(index);
                                                             }
                                                        }
                                                        : null
                                                }
                                            >
                                                <Trash2 color="#FFFFFF" size={18} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="f-16 btn btn-yellow inter-bold"
                    >
                        {"Save"}
                    </button>
                </Modal.Footer>
            </Modal>


        </React.Fragment>
    )
}

export default CampaignForClient