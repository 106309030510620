import React, { useState, useEffect, useReducer } from "react";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faPlus,
    faBan,
    faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";

import EditClientSubscription from "./EditClientSubscription";
import { delete_client_subcription_api, get_app, get_client_api, get_client_subcription_api, get_module_api, get_subcription_api, save_client_subcription_api, } from "../../api";
import { calMaxPage } from "../../common/Functions/CommonFunctions";

const schema = yup
    .object()
    .shape({
        app: yup
            .array()
            .required("Please select application !")
            .min(1, "Please select application !"),
        client: yup
            .array()
            .required("Please select client !")
            .min(1, "Please select client !"),
        subscription: yup
            .array()
            .required("Please select subscription !")
            .min(1, "Please select subscription !"),
    })
    .required();

function ClientSubscription() {

    const [visible, setVisible] = useState(false);

    const [apiBySearch, setApiBySearch] = useState(false);
    const [srNo, setSrNo] = useState(0);
    const [totalRecords, setTotalRecords] = useState("");
    const [app_list, setApp_list] = useState({ data: [], loading: false })
    const [moduList, setModuList] = useState({ data: [], loading: false });
    const [subslist,setSubslist]=useState({data:[],loading:false});
    const [clientlist, setClientlist]=useState({data:[],loading:false})
    const { register, handleSubmit, formState, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    let { errors } = formState;

    const initialState = {
        loading: false,
        error: "",
        ClientSubscriptionList: [],
        itemsPerPage: 10,
        page: 1,
        maxPage: 0,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "HANDLE_ACTION":
                return {
                    page: action.page,
                    itemsPerPage: action.itemsPerPage,
                    loading: action.loading,
                    error: action.error,
                    ClientSubscriptionList: action.ClientSubscriptionList,
                    maxPage: action.maxPage,
                };

            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        console.log("state", state);
        dispatch({
            type: "HANDLE_ACTION",
            page: state.page,
            loading: true,
            error: state.error,
            ClientSubscriptionList: state.ClientSubscriptionList,
            itemsPerPage: state.itemsPerPage,
            maxPage: state.maxPage,
        });

        fetchClientSubscription();
        fetch_app();
        fetchSubcription();
        fetchclient();
        // fetchModList()
    }, [state.page, state.itemsPerPage]);

    function fetchClientSubscription(para1, para2, para3) {
        dispatch({
            type: "HANDLE_ACTION",
            page: para1 === "refresh" ? 1 : state.page,
            loading: true,
            error: state.error,
            ClientSubscriptionList: state.ClientSubscriptionList,
            itemsPerPage: state.itemsPerPage,
            maxPage: state.maxPage,
        });

        var reqPayload = {
            pageNo: para3 == "change" ? state.page - 1 : state.page,
            entriesPerPage: state.itemsPerPage,
        };

        if (para3 == "change") {
            if (state.page == 2) {

                setSrNo(0);
            }
            else {
                let srno = srNo - state.itemsPerPage;
                setSrNo(srno)
            }
        }

        //console.log("reqPayload", reqPayload);

        if (para1 === "search") {
            reqPayload = {
                pageNo: state.page,
                entriesPerPage: state.itemsPerPage,
                searchVal: para2,
            };
        }

        get_client_subcription_api(reqPayload).then(
            (res) => {
                console.log("ClientSubscription res", res);
                console.log(
                    "calMaxPage(res.data.data.total)",
                    // calMaxPage(res.data.total)
                    calMaxPage(res.data.data.response.count.totalData)
                );
                setTotalRecords(res.data.data.response.count.totalData);

                dispatch({
                    type: "HANDLE_ACTION",
                    page: para3 == "change" ? state.page - 1 : state.page,
                    loading: false,
                    error: state.error,
                    ClientSubscriptionList: res.data.data.response.results,
                    itemsPerPage: state.itemsPerPage,
                    maxPage:
                        para1 === "search"
                            ? calMaxPage(res.data.data.response.results.length)
                            : calMaxPage(res.data.data.response.count.totalData),
                });
            },
            (err) => {
                console.log("err", err);

                if (err.response.status === 500) {
                    toast.error("Something went wrong !", { autoClose: 5000 });
                }
            }
        );
    }

    function fetchSubcription() {
        setSubslist({data:[],loading:true})
        get_subcription_api({}).then(
          (res) => {
            console.log("Subcription res", res);
           
            setSubslist({data:res.data.data.response.results,loading:false});
    
            
          },
          (err) => {
            console.log("err", err);
            setSubslist({data:[],loading:true})
           
          }
        );
      }

      
   function fetchclient() {
    setClientlist({data:[],loading:true});

    get_client_api({}).then(
      (res) => {
        console.log("client res", res);
       
        setClientlist({data:res.data.data.response.data,loading:false});

      
      },
      (err) => {
        console.log("err", err);

        setClientlist({data:[],loading:false});
      }
    );
  }
    const onSubmit = (data) => {
        console.log("submitted Data", data);

        if (data !== "") {

            let sub=subslist.data.filter((item)=>item.subs_id==data.subscription[0].subs_id);
            const reqPayload = {
                app_id: data.app[0].app_id,
                subs_id:data.subscription[0].subs_id,
                client_id:data.client[0].client_id,
                no_of_users: sub[0].no_of_users,
                module_list: []
            };

            console.log("reqPayload", reqPayload);


            save_client_subcription_api(reqPayload)
                .then(
                    (res) => {
                        console.log("res", res);
                        if (res.status === 200 || res.status === 201) {
                            toast.success("Subscription assigned successfully !", {
                                autoClose: 3000,
                            });
                            setVisible(false);
                            fetchClientSubscription();
                            reset();
                        }
                    },
                    (err) => {
                        console.log("err.response.status", err.response);
                        if (err.response.data.data.statusCode === 400) {
                            //onsole.log("err.response", err.response);
                            toast.error(err.response.data.data.response, {
                              autoClose: 2000,
                            });
                          }else{
                            toast.error("Something went wrong !", {
                              autoClose: 2000,
                            });
                          }
                    }
                )

        } else {
            errors.showMessages();
        }
    };

    const fetch_app = () => {
        setApp_list({ data: [], loading: true });

        get_app({}).then((res) => {
            console.log("res", res.data.data.response);
            setApp_list({ data: res.data.data.response, loading: false });
        })
            .catch((err) => {
                console.log("err", err);
                setApp_list({ data: [], loading: false });
            })
    }
    // Deleting NC Status
    const delClientSubscription = (val) => {
        //console.log("val", );
        SweetAlert.fire({
            title: "Are you sure ?",
            text: "Once deleted, you will not be able to recover this record !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                // Firing delete api
                const payload = {
                    c_subs_id: val.c_subs_id,
                };
                delete_client_subcription_api(payload).then(
                    (res) => {
                        console.log("res", res);
                        toast.success("Subscription deleted successfully !", {
                            autoClose: 3000,
                        });

                        if (state.page > 1 && state.ClientSubscriptionList.length == 1) {
                            console.log("here");
                            fetchClientSubscription(null, null, "change");
                        }
                        else {
                            fetchClientSubscription();
                        }

                    },
                    (err) => {
                        console.log("err", err);
                        toast.error("Something went wrong !", { autoClose: 3000 });
                    }
                );
            }
        });
    };

    function openNav() {
        document.getElementById("mySidebar").style.width = "320px";
        document.getElementById("root_div_main").style.marginLeft = "320px";
        setTimeout(() => {
            document.getElementById("mySidebar").style.overflowX = "visible";
        }, [200]);
    }

    function closeNav() {
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("root_div_main").style.marginLeft = "0";

        document.getElementById("mySidebar").style.overflowX = "hidden";
    }

    function fetchModList() {
        setModuList({ ...moduList, data: [], loading: true });

        get_module_api().then((res) => {
            console.log("res", res);
            setModuList({ ...moduList, data: res.data.data.response, loading: false });
        }, (err) => {
            setModuList({ ...moduList, data: [], loading: false });
            console.log("err", err);
        })
    }

    return (
        <React.Fragment>
            <ToastContainer />
            {/* Add Item Modal*/}
            <div className="min-vh-100" id="root_div_main">
                <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
                    <Modal.Header
                        style={{ background: "#2A3643", color: "white" }}
                        closeButton
                    >
                        <Modal.Title>{"Assign Subscription"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form action="">

                            <div className="form-group row mt-2">
                                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Application"}
                                    <span className="text-danger">
                                        <i style={{ color: "red" }}>*</i>
                                    </span>
                                </label>
                                <div className="col-md-8">
                                    <Controller
                                        name="app"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead"
                                                labelKey="app_name"
                                                multiple={false}
                                                options={app_list.data}
                                                loading={app_list.loading}
                                                placeholder="Select application"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={value}
                                                isInvalid={errors.app ? true : false}
                                            />
                                        )}
                                    />
                                    <span className="text-danger">{errors.app?.message}</span>
                                </div>
                            </div>
                            <div className="form-group row mt-2">
                                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Client"}
                                    <span className="text-danger">
                                        <i style={{ color: "red" }}>*</i>
                                    </span>
                                </label>
                                <div className="col-md-8">
                                    <Controller
                                        name="client"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead"
                                                labelKey="company_name"
                                                multiple={false}
                                                options={clientlist.data}
                                                loading={clientlist.loading}
                                                placeholder="Select client"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={value}
                                                isInvalid={errors.client ? true : false}
                                            />
                                        )}
                                    />
                                    <span className="text-danger">{errors.client?.message}</span>
                                </div>
                            </div>
                            <div className="form-group row mt-2">
                                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Subscription"}
                                    <span className="text-danger">
                                        <i style={{ color: "red" }}>*</i>
                                    </span>
                                </label>
                                <div className="col-md-8">
                                    <Controller
                                        name="subscription"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead"
                                                labelKey="subscription_name"
                                                multiple={false}
                                                options={subslist.data}
                                                loading={subslist.loading}
                                                placeholder="Select subscription"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={value}
                                                isInvalid={errors.subscription ? true : false}
                                            />
                                        )}
                                    />
                                    <span className="text-danger">{errors.subscription?.message}</span>
                                </div>
                            </div>
                            {/* <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Modules"}
                  <span className="text-danger">
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-8">
                  <Controller
                    name="module"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="module_name"
                        multiple={true}
                        options={moduList.data}
                        loading={moduList.loading}
                        placeholder="Select modules"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.module ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.module?.message}</span>
                </div>
              </div> */}
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={handleSubmit(onSubmit)}
                            className="f-16 btn btn-yellow inter-bold"
                        >
                            {"Save"}
                        </button>
                    </Modal.Footer>
                </Modal>
                <div id="mySidebar" className="customsidebar ">
                    <a className="closebtn" onClick={() => closeNav()}>
                        ×
                    </a>

                    <div className=" content">
                        <div className="filter row">
                            <label
                                className="filterLabel mb-2 inter-bold"
                                style={{ fontSize: "14px" }}
                            >
                                Search
                            </label>
                            <div className="col-12">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder="Search by name"
                                    onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            fetchClientSubscription("search", e.target.value);
                                            setApiBySearch(true);
                                        } else {
                                            fetchClientSubscription();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="my-3 d-flex justify-content-end">
                            <button className="btn btn-sm btn-yellow">
                                <FontAwesomeIcon
                                    style={{ color: "#344454" }}
                                    icon={faBan}
                                    size="sm"
                                />{" "}
                                Clear Filter
                            </button>
                            {/* <button
                          className="btn btn-sm btn-grey ms-0 ms-3 px-4"
                      >
                          <FontAwesomeIcon style={{ color: "#fff" }} icon={faSearch} size="sm" />{" "}
                          Search
                      </button> */}
                        </div>
                    </div>
                </div>
                <div className="content-wrapper">
                    <div className="d-flex justify-content-between">
                        <label className="page-heading">{"Client Subscriptions"}</label>
                        <div>
                            {/* <button
                className="btn btn-grey px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faFilter}
                  size="sm"
                />
                Filter Menu
              </button> */}

                            <button
                                className="btn btn-pink px-4"
                                onClick={() => {
                                    setVisible(true);
                                    reset();
                                }}
                            >
                                <FontAwesomeIcon
                                    style={{ color: "#fff" }}
                                    icon={faPlus}
                                    size="sm"
                                />{" "}
                                {"Assign Subscription"}
                            </button>
                        </div>
                    </div>
                    <div className="card border-0 mt-3">
                        <div className="card-body">
                            {/* <div className="d-flex justify-content-start mb-3 me-3">
                                <div>
                                    <p className="mb-0">
                                        Show
                                        <select
                                            className="mx-1"
                                            defaultValue={"10"}
                                            // onChange={(e) => {
                                            //   setEntriesPerPage(e.target.value);
                                            //   fetchProdConfig(page, e.target.value);
                                            // }}
                                            onChange={(e) => {
                                                dispatch({
                                                    type: "HANDLE_ACTION",
                                                    page: 1,
                                                    loading: state.loading,
                                                    error: state.error,
                                                    ClientSubscriptionList: state.ClientSubscriptionList,
                                                    itemsPerPage: e.target.value,
                                                    maxPage: state.maxPage,
                                                })
                                                setSrNo(0);
                                            }

                                            }
                                        >
                                            <option value={"10"}>10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        Entries
                                    </p>
                                </div>
                            </div> */}
                            <div className="mt-3">
                                {state.loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : state.ClientSubscriptionList && state.ClientSubscriptionList.length > 0 ? (
                                    <div className="table-responsive">
                                        <table
                                            className="table table-striped table-sm"
                                            width={"100%"}
                                        >
                                            <thead className="table-grey text-ceter inter">
                                                <tr className="f-14">
                                                    <th scope="col" width="15%">
                                                        {"Sr. No"}
                                                    </th>
                                                    <th scope="col">{"Client"}</th>
                                                    <th scope="col">{"Subscription Name"}</th>
                                                    <th scope="col" width="15%">
                                                        {"Action"}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="inter">
                                                {state.ClientSubscriptionList.map((val, i) => (
                                                    <tr key={i} >
                                                        <td>{i + srNo + 1}</td>
                                                        <td>{val.company_name}</td>
                                                        <td>{val.subscription_name}</td>
                                                        <td>
                                                            <EditClientSubscription
                                                                data={val}
                                                                fetchClientSubscription={fetchClientSubscription}
                                                                app_list={app_list.data}
                                                                subslist={subslist.data}
                                                                clientlist={clientlist.data}
                                                            />
                                                            <Tooltip id={"del-tooltip" + i} place="top" />
                                                            <div
                                                                data-tooltip-id={"del-tooltip" + i}
                                                                data-tooltip-content="Delete Subscription"
                                                                className="deleteIcon me-2"
                                                                onClick={() => delClientSubscription(val)}
                                                            >
                                                                <Trash2 color="#FFFFFF" size={18} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : apiBySearch ? (
                                    <p className="text-danger mb-0 f-22 text-center mt-4">
                                        {"No Subscription found !"}
                                    </p>
                                ) : (
                                    <p className="text-danger mb-0 f-22 text-center mt-4">
                                        {"Subscription list is empty"}
                                    </p>
                                )}

                                {state.loading ? null : (
                                    <div className="mt-4 d-flex justify-content-between">
                                        <p className='mb-0'>{`Showing ${parseInt(srNo) + 1} to ${Math.min(parseInt(state.itemsPerPage) + parseInt(srNo), totalRecords)} of ${totalRecords} entries`}</p>
                                        <Pagination>
                                            <Pagination.Prev
                                                disabled={state.page === 1 ? true : false}
                                                onClick={() => {
                                                    dispatch({
                                                        type: "HANDLE_ACTION",
                                                        page: state.page - 1,
                                                        loading: state.loading,
                                                        error: state.error,
                                                        ClientSubscriptionList: state.ClientSubscriptionList,
                                                        itemsPerPage: state.itemsPerPage,
                                                        maxPage: state.maxPage,
                                                    });
                                                    setSrNo((prevC) => prevC - parseInt(state.itemsPerPage));
                                                }}
                                            >
                                                {"Prev"}
                                            </Pagination.Prev>

                                            <Pagination.Item active>{state.page}</Pagination.Item>

                                            <Pagination.Next
                                                disabled={
                                                    state.page === state.maxPage || state.maxPage === 0
                                                        ? true
                                                        : false
                                                }
                                                onClick={() => {
                                                    dispatch({
                                                        type: "HANDLE_ACTION",
                                                        page: state.page + 1,
                                                        loading: state.loading,
                                                        error: state.error,
                                                        ClientSubscriptionList: state.ClientSubscriptionList,
                                                        itemsPerPage: state.itemsPerPage,
                                                        maxPage: state.maxPage,
                                                    });
                                                    setSrNo((prevC) => prevC + parseInt(state.itemsPerPage));
                                                }}
                                            >
                                                {"Next"}
                                            </Pagination.Next>
                                        </Pagination>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ClientSubscription