import React, { useState, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Download, Edit2, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap";
import { Tooltip } from 'react-tooltip'
import { delete_app, get_app, save_app } from "../../api";
import axios from "axios";
import { useNavigate,} from "react-router-dom";

const schema = yup
  .object()
  .shape({
    app_name: yup
      .string()
      .required("App name is required !")
  })
  .required();

function Application() {
  const [visible, setVisible] = useState(false);
  let navigate = useNavigate();
  const [srNo, setSrNo] = useState(0);
const [app_list,setApp_list]=useState({data:[],loading:false})
  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;



  useEffect(() => {
   
    fetch_app()
  }, []);

  

  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data !== "") {
      const reqPayload = {
        app_name: data.app_name,
      };

      console.log("reqPayload", reqPayload);
    save_app(reqPayload).then((res)=>{
        console.log("res",res);
        if (res.status === 200 || res.status === 201) {
          toast.success("Application Created Successfully !", {
            autoClose: 3000,
          });
        
          fetch_app();
          setVisible(false)
        }

        
    }).catch((err)=>{
        console.log("err",err)
    })
    } else {
      errors.showMessages();
    }
  };

  const fetch_app=()=>{
    setApp_list({data:[],loading:true});

    get_app({}).then((res)=>{
        console.log("res",res.data.data.response);
        setApp_list({data:res.data.data.response,loading:false});
    })
    .catch((err)=>{
        console.log("err",err);
        setApp_list({data:[],loading:false});
    })
  }

  // Deleting Reason
  const delapp = (val) => {
    //console.log("val", );
    SweetAlert.fire({
      title: "Are you sure? \n" ,
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
            app_id: val.app_id,
        };
       delete_app(payload).then((res)=>{
         console.log("res",res);

         if (res.status === 200 || res.status === 201) {
          toast.success("Application deleted Successfully !", {
            autoClose: 3000,
          });
        
          fetch_app();
        }else{
          toast.success("Something went wrong !", {
            autoClose: 3000,
          });
          fetch_app();
        }
        
       })
       .catch((err)=>{
         console.log("err",err);
         console.log("err.response",err.response);
         console.log("err.response.data",err.response.data.data.statusCode);
         if(err.response.data.data.statusCode==400){
          toast.error("Application already has modules !", {
            autoClose: 3000,
          });
          return
         }
        toast.error("Something went wrong !", {
          autoClose: 3000,
        });
        fetch_app();
        console.log("err",err)
       })
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{"Add Application"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-4"
                >
                  Application Name
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="app_name"
                    name="app_name"
                    placeholder="Enter application name"
                    {...register("app_name")}
                  />
                  <span className="text-danger">
                    {errors.app_name?.message}
                  </span>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow inter-bold"
            >
              {"Save"}
            </button>
          </Modal.Footer>
        </Modal>
       
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <label className="page-heading">{"Application"}</label>
            <div>
             

              <button
                className="btn btn-pink px-4"
                onClick={() => {
                  setVisible(true);
                  //navigate("/create-application");
                  reset();
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                Add App
              </button>
            </div>
          </div>
          <div className="card border-0 mt-4">
            <div className="card-body">
              <div className="d-flex justify-content-start mt-1 me-3">
                <div>
                
                </div>
              </div>
              <div className="mt-3">
                {app_list.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : app_list.data && app_list.data.length > 0 ? (
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-sm"
                      width={"100%"}
                    >
                      <thead className="table-grey text-ceter inter">
                        <tr className="f-14 ">
                          <th scope="col" width="15%">
                            {"Sr.No"}
                          </th>
                          <th scope="col">{"Application Name"}</th>
                          <th scope="col" width="15%">
                            {"Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="inter">
                        {app_list.data.map((val, i) => (
                          <tr key={i} >
                            {/* <td>{val.NCReasonId}</td> */}
                            <td>{i + srNo + 1}</td>
                            <td>{val.app_name}</td>
                            <td>

                            <Tooltip id={"edit-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"edit-tooltip" + i}
                                data-tooltip-content="Edit Application"
                                onClick={() =>
                                  navigate("/edit-application", { state: val })
                                }
                                className="editIcon m-1 cursor_pointer"
                              >
                                <Edit2 color="#FFFFFF" size={18} />
                              </div>
                              

                              <Tooltip id={"del-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"del-tooltip" + i}
                                data-tooltip-content="Delete Application"
                                className="deleteIcon me-2"
                                onClick={() => delapp(val)}
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>

                             
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )  : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Application list is empty"}
                  </p>
                )}

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Application;
