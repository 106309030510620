import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "react-tooltip";
import { get_module_screen_api, update_module_api } from "../../api";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Module name is required !"),
    app: yup
      .array()
      .required("Please select application !")
      .min(1, "Please select application !"),
    screen: yup
      .array()
      .required("Please select screen !")
      .min(1, "Please select screen !"),
  })
  .required();

function EditModule(props) {
  const [visible, setVisible] = useState(false);
  const { register, handleSubmit, formState, control, reset, setValue, watch } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    console.log("props", props);
    if (visible == true) {
      console.log("inside", props);
      fetchScreen(props.data.module_id);
      let a = props.app_list.filter((item) => item.app_id == props.data.app_id);
      setValue("app", a);
    }
  }, [visible]);
  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data !== "") {
      const reqPayload = {
        app_id: data.app[0].app_id,
        module_name: data.name,
        module_information: data.remark,
        screens: data.screen,
      };

      console.log("reqPayload", reqPayload);

      update_module_api({ module_id: props.data.module_id }, reqPayload)
        .then(
          (res) => {
            console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Module updated Successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              props.fetchModule();
            }
          },
          (err) => {
            console.log("err.response.status", err.response.status);

            if (err.response.data.data.statusCode === 400) {
              //onsole.log("err.response", err.response);
              toast.error("Module already exists !", {
                autoClose: 2000,
              });
            } else {
              toast.error("Something went wrong !", {
                autoClose: 2000,
              });
            }
          }
        )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  function fetchScreen(id) {
    get_module_screen_api({ module_id: id }).then(
      (res) => {
        console.log("Screen res", res);

        if (res.data.data.response.data.length > 0) {
          let ar = res.data.data.response.data.map((item) => {
            //  console.log("ar", item.label_list);
            let obj = {
              screen_name: item.screen_name,
              display_name: item.display_name,
              labelList: item.label_list,
            };

            return obj;
          });
          console.log("ar", ar);

          setValue("screen", ar);
        } else {
          setValue("screen", []);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <div
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content="Edit Module"
        className="editIcon me-2"
      >
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
      {/* </OverlayTrigger> */}
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Update Module"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                {"Application"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="app"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="app_name"
                      multiple={false}
                      options={props.app_list}
                      // loading={app_list.loading}
                      placeholder="Select application"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      disabled={true}
                      isInvalid={errors.app ? true : false}
                    />
                  )}
                />
                <span className="text-danger">{errors.app?.message}</span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Module Name
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="name"
                  name="name"
                  placeholder="Enter module name"
                  defaultValue={
                    props.data !== undefined && props.data !== ""
                      ? props.data.module_name
                      : ""
                  }
                  {...register("name")}
                />
                <span className="text-danger">{errors.name?.message}</span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                {"Screen"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="screen"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="screen_name"
                      multiple={true}
                      options={props.screen_list}
                      placeholder="Select screen"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.screen ? true : false}
                    />
                  )}
                />
                <span className="text-danger">{errors.screen?.message}</span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Module Information
              </label>
              <div className="col-md-8">
                <textarea
                  type="text"
                  className="form-control form-control-sm"
                  id="remark"
                  name="remark"
                  placeholder="Enter remark"
                  defaultValue={
                    props.data.module_information !== null && props.data !== ""
                      ? props.data.module_information
                      : ""
                  }
                  rows={2}
                  {...register("remark")}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditModule;
