import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "react-tooltip";
import {
  save_module_api,
  save_module_screen_api,
  update_module_api,
} from "../../api";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Module name is required !"),
    app: yup
      .array()
      .required("Please select application !")
      .min(1, "Please select application !"),
    screen: yup
      .array()
      .required("Please select screen !")
      .min(1, "Please select screen !"),
  })
  .required();

function AddScreenModule({
  module_data,
  data,
  visible,
  setVisible,
  screen_list,
  fetch_app,
}) {
  const { register, handleSubmit, formState, control, reset, setValue, watch } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    console.log("props", data);
    console.log("module_data", module_data);
    reset();
    if (visible == true) {
      let a = [{ app_id: data.app_id, app_name: data.app_name }];
      setValue("app", a);
      setValue("name", module_data.module_name);
    }
  }, [visible]);
  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data !== "") {
      const reqPayload = {
        module_id: module_data.module_id,
        screens: data.screen,
      };

      console.log("reqpayload", reqPayload);

      save_module_screen_api(reqPayload)
        .then(
          (res) => {
            console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Screen added successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              fetch_app();
              reset();
            }
          },
          (err) => {
            console.log("err.response.status", err.response.status);

            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
            reset();
          }
        )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Add Screen"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                {"Application"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="app"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="app_name"
                      multiple={false}
                      //options={[]}
                      // loading={app_list.loading}
                      disabled={true}
                      placeholder="Select application"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.app ? true : false}
                    />
                  )}
                />
                <span className="text-danger">{errors.app?.message}</span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Module Name
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="name"
                  name="name"
                  disabled={true}
                  placeholder="Enter module name"
                  {...register("name")}
                />
                <span className="text-danger">{errors.name?.message}</span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                {"Screen"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="screen"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="screen_name"
                      multiple={true}
                      options={screen_list}
                      placeholder="Select screen"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.screen ? true : false}
                    />
                  )}
                />
                <span className="text-danger">{errors.screen?.message}</span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default AddScreenModule;
