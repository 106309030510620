import React, { useEffect, useState, Suspense } from "react";
import { useNavigate, Route, Routes, Navigate } from "react-router-dom";
import routes from '../Routes/Routes'
import "../App.css";

function AppContent() {
  let navigate = useNavigate();
  //   const token = localStorage.getItem("token");
  const token = localStorage.getItem('access');
//  console.log("token",token)
if(!token){
  navigate('/login')
}
  return (
    <Routes>
      {token && token.length > 0 ?
        routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
               // exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          );
        }) :
        < Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      }

    </Routes>
  );
}

export default AppContent;
