
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast ,ToastContainer} from "react-toastify";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    key: yup.string().required("Screen label key is  required !"),
    value: yup.string().required("Screen label value is  required !"),
  })
  .required();

export const Addlabel = ({visible,setVisible, setLabels,labels }) => {
  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data.key && !labels[data.key]) {
      const updatedLabels = { ...labels, [data.key]: data.value };
      setVisible(false);
      setLabels(updatedLabels);
      reset()
    } else {
      toast.error("The key is already present !", {
        autoClose: 2000,
      });
    }
  };

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;
  return (
    <div>
      <ToastContainer />
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Add Key"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Screen Label Key
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="key"
                  name="key"
                  placeholder="Enter screen label key"
                  {...register("key")}
                />
                <span className="text-danger">{errors.key?.message}</span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Screen Lable Value
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="value"
                  name="value"
                  placeholder="Enter screen lable value "
                  {...register("value")}
                />
                <span className="text-danger">{errors.key?.message}</span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
