import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { Pagination } from "react-bootstrap";
import { Edit2,  Trash2 } from "react-feather";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { delete_role_api, get_app, get_role_api } from "../../api";
import { calMaxPage } from "../../common/Functions/CommonFunctions";

function Roles() {
  const [page, setPage] = useState(0);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [roleList, setRoleList] = useState({ data: [], loading: false ,totalRecords:""});
  const [app_list, setApp_list] = useState({ data: [], loading: false });

  let navigate = useNavigate();

  useEffect(() => {
    fetchRoles(page,entriesPerPage);
    fetch_app();
  }, []);

  function fetchRoles(a,b) {
    setRoleList({ ...roleList, data: [], loading: true });

    const payload = {
      limit: b,
      offset: a,
    };

    get_role_api(payload).then(
      (res) => {
        console.log("res", res);
        setRoleList({
          ...roleList,
          data: res.data.data.response.data,
          loading: false,
          totalRecords:res.data.data.response.total
        });
        setMaxPage(calMaxPage(res.data.data.response.total))
      },
      (err) => {
        setRoleList({ ...roleList, data: [], loading: false, totalRecords:""});
        console.log("err", err);
      }
    );
  }
  const fetch_app = () => {
    setApp_list({ data: [], loading: true });

    get_app({})
      .then((res) => {
        console.log("res", res.data.data.response);
        setApp_list({ data: res.data.data.response, loading: false });
      })
      .catch((err) => {
        console.log("err", err);
        setApp_list({ data: [], loading: false });
      });
  };
  const delRole = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.name + " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_role_api({ role_id: val.role_id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Role deleted successfully", {
              autoClose: 2000,
            });
            fetchRoles(0,10);
            // if (roleList.data.length == 1) {

            // } else {

            // }
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="roles">
        <div className="d-flex justify-content-between">
          <h1 className="page-heading inter-bold">{"Roles"}</h1>
          <button
            className="btn btn-pink px-4"
            onClick={() => {
              navigate("/create-role");
            }}
          >
            <FontAwesomeIcon
              style={{ color: "#fff" }}
              icon={faPlus}
              size="sm"
            />{" "}
            Create New Role
          </button>
        </div>

        <div className="row">
          <div className="col-12">
            {/* <div className='entriesPerPage inter-bold d-flex align-items-end'>
                            <p className='mb-0'>Show
                                <select className='mx-1' defaultValue={'10'} onChange={(e) => {
                                    setEntriesPerPage(e.target.value);
                                    fetchRoles(page, e.target.value)
                                }}>
                                    <option value={'10'}>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>Entries</p>

                        </div> */}
            <div className="card border-0 mt-3 mb-4">
              <div className="card-body">
                {roleList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : roleList.data?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table mt-2 table-striped table-sm">
                      <thead className="table-grey  inter-bold">
                        <tr className="">
                          <th scope="col">{"SrNo"}</th>
                          <th scope="col">{"Role Name"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody className=" inter">
                        {roleList.data.map((val, i) => (
                          <tr key={i}>
                            <td style={{ verticalAlign: "middle" }}>
                              {parseInt(i) + 1 + parseInt(srNo)}
                            </td>
                            <td>{val.name}</td>

                            <td className="">
                              <Tooltip id={"edit-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"edit-tooltip" + i}
                                data-tooltip-content="Edit Role"
                                onClick={() =>
                                  navigate("/update-role", {
                                    state: { ...val, app_list: app_list.data },
                                  })
                                }
                                className="editIcon m-1 cursor_pointer"
                              >
                                <Edit2 color="#FFFFFF" size={18} />
                              </div>

                              <Tooltip id={"delete-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"delete-tooltip" + i}
                                data-tooltip-content="Delete Role"
                                onClick={() => delRole(val)}
                                className="deleteIcon m-1 cursor_pointer"
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-danger mb-0">No Roles Found</p>
                )}

                {roleList.loading ? null : roleList.data?.length > 0 ? (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
                      roleList.data.length +
                      entriesPerPage * (page+1) -
                      entriesPerPage
                    } of ${roleList.totalRecords} entries`}</p>
                    <Pagination>
                      <Pagination.Prev
                        disabled={(page+1) === 1 ? true : false}
                        
                        onClick={() => {
                          fetchRoles(page-1,10)
                          setSrNo((prevC) =>
                            page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                          );
                          setPage(page - 1);
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{page+1}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          (page+1) === maxPage ||
                          maxPage === 0 ||
                          entriesPerPage > roleList.data.length
                            ? true
                            : false
                        }
                        onClick={() => {
                          // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                           fetchRoles(page+1,10)
                          setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                          setPage(page + 1);
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Roles;
