import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Tooltip } from "react-tooltip";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateStatusMaster } from "../../../api";

const schema = yup
  .object()
  .shape({
    status_name: yup
      .string()
      .required("Status name is required !"),
    model_name: yup
      .string()
      .required("Model name is required !"),
  })
  .required();

function EditStatusMaster(props) {
    const [visible, setVisible] = useState(false);

    const { register, handleSubmit, formState, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
      }); // initialise the hook
    
      let { errors } = formState;

    const onSubmit = (data) => {
        
    
        if (data !== "") {
          const reqPayload = {
            uuid: props.data?.uuid,
            status_name: data.status_name.trim(),
            model_name: data.model_name.trim(),
          };
    
          
    
          updateStatusMaster(reqPayload)
            .then(
              (res) => {
                
                if (res.status === 200 || res.status === 201) {
                  toast.success("Status updated successfully !", {
                    autoClose: 3000,
                  });
                  setVisible(false);
                  props.fetchStatusList();
                }
              },
              (err) => {
                
                if (err.response.status === 400) {
                  
                  toast.error(err.response.data.message, {
                    autoClose: 2000,
                  });
                  reset();
                }
              }
            )
        } else {
          errors.showMessages();
        }
      };
  return (
    <React.Fragment>
    <Tooltip id={"edit-tooltip"} place="top" />
      <div
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={props.constLabel?.lbl_edit_status ? props.constLabel.lbl_edit_status : "Edit Status"} className="editIcon me-2">
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
    <Modal show={visible} onHide={() => {setVisible(false); reset()}} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{props.constLabel?.lbl_update_status ? props.constLabel.lbl_update_status : "Update Status"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form action="">
        <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >{props.constLabel?.lbl_status_name ? props.constLabel.lbl_status_name : "Status Name"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text" 
                  className={
                      errors.status_name
                        ? "form-control  is-invalid"
                        : "form-control "
                    }
                  id="status_name"
                  name="status_name"
                  placeholder="Enter status name"
                  defaultValue={props.data?.status_name}
                  {...register("status_name")}
                />
                <span className="text-danger err-msg">
                  {errors.status_name?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_model_name ? props.constLabel.lbl_model_name : "Model Name"}<span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text" 
                  className={
                      errors.model_name
                        ? "form-control  is-invalid"
                        : "form-control "
                    }
                  id="model_name"
                  name="model_name"
                  defaultValue={props.data?.model_name}
                  placeholder="Enter model name"
                  {...register("model_name")}
                />
                <span className="text-danger err-msg">
                  {errors.model_name?.message}
                </span>
              </div>
            </div>  
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>

  </React.Fragment>
  )
}

export default EditStatusMaster