import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Pagination } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import {
  get_campaign_master_api,
  delete_campaign_master_api,
  save_campaign_master_api,
} from "../../api";
import {
  calMaxPage,
  convertDate,
  convertDateYYMMDD,
} from "../../common/Functions/CommonFunctions";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UpdateCampaignMaster from "./UpdateCampaignMaster";

const schema = yup
  .object()
  .shape({
    campaign_name: yup.string().required("Campaign name is required !"),
    date: yup
      .date()
      .typeError("Please select campaign created date!")
      .required("Please select a campaign created date!"),
  })
  .required();
export function CampaignMaster() {
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [campaignList, setcampaignList] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setError,
    clearErrors,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    fetchChampaign(page, entriesPerPage);
      // console.log("dsfjkl")
  }, []);

  useEffect(() => {
   
    if(visible){
      reset();
      // console.log("reset");
      //  console.log("sta",startDate);
     

         setStartDate(new Date())
     

         setValue('date',new Date())
       
    }
    
    
  }, [visible]);
  function fetchChampaign(a, b) {
    setcampaignList({ ...campaignList, data: [], loading: true });

    const payload = {
      pageSize: b,
      page: a,
    };

    get_campaign_master_api(payload).then(
      (res) => {
        console.log("res", res.data.data);
        setcampaignList({
          ...campaignList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.totalCount,
        });
        setMaxPage(calMaxPage(res.data.totalCount));
      },
      (err) => {
        setcampaignList({
          ...campaignList,
          data: [],
          loading: false,
          totalRecords: "",
        });
        console.log("err", err);
      }
    );
  }

  const delCampaign = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.campaign_name + " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {

      if (result.value) {
        delete_campaign_master_api({ uuid: val.uuid }).then(
          (res) => {
            console.log("res", res);
            toast.success("Campaign deleted successfully", {
              autoClose: 2000,
            });

             let value=campaignList.totalRecords%10;

             if(value==1&&page!==1){
              fetchChampaign(page-1, 10);
              setPage(page-1);
              setSrNo(srNo-10)
             }else{
              fetchChampaign(page, 10)
             }
           
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  const onSubmit = (data) => {
    console.log("data", data);

    if (data !== "") {
      const reqPayload = {
        campaign_name: data.campaign_name,
        campaign_created_date: convertDateYYMMDD(startDate, "-"),
      };

      console.log("reqPayload", reqPayload);
      
      save_campaign_master_api(reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Campaign created successfully !", {
              autoClose: 2000,
            });
            fetchChampaign(page, 10);
            // setSrNo(0);
            // setPage(1)
            setVisible(false);
            reset();
          }
        },
        (err) => {
          toast.error("Something went wrong !", {
            autoClose: 2000,
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Add Campaign"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Campaign Name
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="campaign_name"
                  name="campaign_name"
                  placeholder="Enter campaign name"
                  {...register("campaign_name")}
                />
                <span className="text-danger">
                  {errors.campaign_name?.message}
                </span>
              </div>
            </div>

            <div className=" row mt-2">
              <label
                className="col-form-label col-md-4"
                style={{ fontSize: "14px" }}
              >
                {"Campaign Created Date"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              {/* <div className="col-12 col-md-8">
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <DatePicker
                      className={"form-control"}
                      placeholderText="Select campaing created date"
                      selected={startDate}
                      onChange={(date) => {
                        console.log("date",date)
                        if(!date){
                          setError('date', { type: 'custom', message: 'Campaign creation date is required !' });
                          setStartDate(null)
                        }
                        else{

                          setStartDate(date);
                          clearErrors('date'); 

                        }
                      }}
                      maxDate={startDate}
                      dateFormat="dd/MM/yyyy"
                      autoComplete="false"
                    />
                  )}
                />
                 <span className="text-danger">
                  {errors.date?.message}
                </span>
              </div> */}

              <div className="col-12 col-md-8">
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <DatePicker
                      className={
                        errors.date
                          ? "form-control  is-invalid"
                          : "form-control  "
                      }
                      placeholderText="Select campaign creation date"
                      selected={startDate}
                      onChange={(date) => {
                        field.onChange(date);
                        setStartDate(date);
                      }}
                      maxDate={startDate}
                      dateFormat="dd/MM/yyyy"
                      autoComplete="false"
                    />
                  )}
                />

                {errors.date && (
                  <span className="text-danger ">
                    {errors.date.message}
                  </span>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="roles">
        <div className="d-flex justify-content-between">
          <h1 className="page-heading inter-bold">{"Campaigns"}</h1>
          <button
            className="btn btn-pink px-4"
            onClick={() => {
              setVisible(true);
            }}
          >
            <FontAwesomeIcon
              style={{ color: "#fff" }}
              icon={faPlus}
              size="sm"
            />{" "}
            Create New Campaign
          </button>
        </div>

        <div className="row">
          <div className="col-12">
            {/* <div className='entriesPerPage inter-bold d-flex align-items-end'>
                            <p className='mb-0'>Show
                                <select className='mx-1' defaultValue={'10'} onChange={(e) => {
                                    setEntriesPerPage(e.target.value);
                                    fetchChampaign(page, e.target.value)
                                }}>
                                    <option value={'10'}>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>Entries</p>

                        </div> */}
            <div className="card border-0 mt-3 mb-4">
              <div className="card-body">
                {campaignList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : campaignList.data?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table mt-2 table-striped table-sm">
                      <thead className="table-grey  inter-bold">
                        <tr className="">
                          <th scope="col">{"Sr. No"}</th>
                          <th scope="col">{"Campaign Name"}</th>
                          <th scope="col">{"Campaign Creation Date"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody className=" inter">
                        {campaignList.data.map((val, i) => (
                          <tr key={i}>
                            <td style={{ verticalAlign: "middle" }}>
                              {parseInt(i) + 1 + parseInt(srNo)}
                            </td>
                            <td>{val.campaign_name}</td>
                            <td>
                              {convertDate(val.campaign_created_date, "-")}
                            </td>

                            <td className="">
                              <UpdateCampaignMaster
                                data={val}
                                fetchChampaign={fetchChampaign}
                                page={page}
                              />

                              <Tooltip id={"delete-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"delete-tooltip" + i}
                                data-tooltip-content="Delete Campaign"
                                onClick={() => delCampaign(val)}
                                className="deleteIcon m-1 cursor_pointer"
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-danger mb-0">
                    No Campaign's Found
                  </p>
                )}

                {campaignList.loading ? null : campaignList.data?.length > 0 ? (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
                      campaignList.data.length +
                      entriesPerPage * page -
                      entriesPerPage
                    } of ${campaignList.totalRecords} entries`}</p>
                    <Pagination>
                      <Pagination.Prev
                        disabled={page === 1 ? true : false}
                        onClick={() => {
                          fetchChampaign(page - 1, 10);
                          setSrNo((prevC) =>
                            page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                          );
                          setPage(page - 1);
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{page}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          page === maxPage ||
                          maxPage === 0 ||
                          entriesPerPage > campaignList.data.length
                            ? true
                            : false
                        }
                        onClick={() => {
                          // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                          fetchChampaign(page + 1, 10);
                          setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                          setPage(page + 1);
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
