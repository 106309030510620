import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from 'react-tooltip'
import { update_subcription_api } from "../../api";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    app: yup
      .array()
      .required("Please select application !")
      .min(1, "Please select application !"),
    client: yup
      .array()
      .required("Please select client !")
      .min(1, "Please select client !"),
    subscription: yup
      .array()
      .required("Please select subscription !")
      .min(1, "Please select subscription !"),

  })
  .required();

function EditClientSubscription(props) {
  const [visible, setVisible] = useState(false);
  const { register, handleSubmit, formState, control, reset, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    console.log("props", props);
    if (visible == true) {
      console.log("inside", props);
      let a = props.app_list.filter((item) => item.app_id == props.data.app_id);
      console.log("a", a);
      let b=props.clientlist.filter((item)=>item.client_id==props.data.client_id);
      let c=props.subslist.filter((item)=>item.subs_id==props.data.subs_id);
      setValue("app", a);
      setValue("client",b);
      setValue("subscription",c);
    }
  }, [visible])
  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data !== "") {
      const reqPayload = {
        app_id: data.app[0].app_id,
        subs_id:data.subscription[0].subs_id,
        client_id:data.client[0].client_id,
        no_of_users: props.data.no_of_users,
        module_list: []
      };

      console.log("reqPayload", reqPayload);

      update_subcription_api({ c_subs_id: props.data.c_subs_id }, reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Subscription updated Successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchClientSubscription();
          }
        },
        (err) => {
          console.log("err.response.status", err.response.status);
          if (err.response.data.data.statusCode === 400) {
            //onsole.log("err.response", err.response);
            toast.error(err.response.data.data.response, {
              autoClose: 2000,
            });
          }else{
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        }
      )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>

      <Tooltip id={"edit-tooltip"} place="top" />
      <div data-tooltip-id={"edit-tooltip"}
        data-tooltip-content="Edit Subscription" className="editIcon me-2">
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
      {/* </OverlayTrigger> */}
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Update Client Subcription"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">

            <div className="form-group row mt-2">
              <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Application"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="app"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="app_name"
                      multiple={false}
                      options={props.app_list}
                      //    loading={app_list.loading}
                      placeholder="Select application"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.app ? true : false}
                    />
                  )}
                />
                <span className="text-danger">{errors.app?.message}</span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Client"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="client"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="company_name"
                      multiple={false}
                      options={props.clientlist}
                      // loading={clientlist.loading}
                      placeholder="Select client"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.client ? true : false}
                    />
                  )}
                />
                <span className="text-danger">{errors.client?.message}</span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Subscription"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="subscription"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="subscription_name"
                      multiple={false}
                      options={props.subslist}
                      // loading={subslist.loading}
                      placeholder="Select subscription"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.subscription ? true : false}
                    />
                  )}
                />
                <span className="text-danger">{errors.subscription?.message}</span>
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  );
}

export default EditClientSubscription;

