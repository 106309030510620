import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "react-tooltip";
import { update_campaign_master_api } from "../../api";
import DatePicker from "react-datepicker";
import { convertDateYYMMDD } from "../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    campaign_name: yup.string().required("Campaign name is required !"),
    date: yup
    .date()
    .typeError("Please select campaign created date!")
    .required("Please select a campaign created date!"),
  })
  .required();

function UpdateCampaignMaster(props) {
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(props.data.campaign_created_date)
  );

  const { register, handleSubmit, formState, control, reset, setValue, watch } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
  
    //  console.log("visible",visible)
    if(visible){
      reset();
      setStartDate(new Date(props.data.campaign_created_date))
      setValue('date',new Date(props.data.campaign_created_date))
    }
      
    
  }, [visible]);

  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data !== "") {
      const reqPayload = {
        campaign_name: data.campaign_name,
        campaign_created_date: convertDateYYMMDD(startDate, "-"),
      };
      console.log("reqPayload", reqPayload);

    
      update_campaign_master_api({ uuid: props.data.uuid }, reqPayload)
        .then(
          (res) => {
            console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Campaign updated successfully !", {
                autoClose: 2000,
              });
              setVisible(false);
              props.fetchChampaign(props.page, 10);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <div
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content="Edit Campaign"
        className="editIcon me-2"
      >
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
      {/* </OverlayTrigger> */}
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Update Champaign"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Campaign Name
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="campaign_name"
                  name="campaign_name"
                  placeholder="Enter campaign name"
                  defaultValue={props.data.campaign_name}
                  {...register("campaign_name")}
                />
                <span className="text-danger">
                  {errors.campaign_name?.message}
                </span>
              </div>
            </div>

            {/* <div className=" row mt-2">
              <label
                className="col-form-label col-md-4"
                style={{ fontSize: "14px" }}
              >
                {"Campaign Created Date"}
              </label>
              <div className="col-12 col-md-8">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <DatePicker
                      className={"form-control"}
                      placeholderText="Select campaing created date"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      autoComplete="false"
                    />
                  )}
                />
              </div>
            </div> */}

            <div className="row mt-2">
            <label
                className="col-form-label col-md-4"
                style={{ fontSize: "14px" }}
              >
                {"Campaign Created Date"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-12 col-md-8">
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <DatePicker
                      className={
                        errors.date
                          ? "form-control  is-invalid"
                          : "form-control  "
                      }
                      placeholderText="Select campaign creation date"
                      selected={startDate}
                      onChange={(date) => {
                        field.onChange(date);
                        setStartDate(date);
                      }}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      autoComplete="false"
                    />
                  )}
                />

                {errors.date && (
                  <span className="text-danger ">{errors.date.message}</span>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default UpdateCampaignMaster;
