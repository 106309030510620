import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom';
import {  update_client_api } from '../../api';
import {
  faPlus,
  faBan,
  faFilter,
  faEye, faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import { Addtablelable } from './Addtablelable';
import { Edit2, Trash2 } from 'react-feather';

const schema = yup
  .object()
  .shape({
    company_name: yup.string().required("Company name is required !"),
    contact_no: yup
      .string()
      .required("Please enter contact number !")
      .matches(/^[0-9]+(\.[0-9]+)?$/, 'Enter valid contact number !'),
    email: yup
      .string()
      .email("Invalid email address !")
      .required("Please enter mail !"),
    industry: yup.string().required("Industry is required !"),
    organization: yup.string().required("Organization is required !"),
    gst_no: yup
      .string()
      .required("Please enter GST number !")
      .max(15, 'GST number must be 15 characters only !')
      .matches(
        /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[zZ]{1}[0-9]{1}$/,
        'Invalid GST Number'
      ),
    pan_no: yup
      .string()
      .required('PAN Number is required')
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'Invalid PAN Number'),



  })
  .required();

function EditClient() {
  let navigate = useNavigate();
  let location = useLocation();
   console.log("location",location);
  const {client_id,company_name,company_type,contact_no,email,gst_no,industry,organization,pan_no,website,configuration}=location.state; 

useEffect(()=>{

  if(configuration!==null&&configuration!==undefined){
    if(Object.keys(configuration).length>0){
     
      let obj_key_arr=Object.keys(configuration);
      let obj_val_arr=Object.values(configuration)
      
      let arr=obj_key_arr.map((item,ind)=>{
        let obj={
          key:"label",
          value:item,
          flag:obj_val_arr[ind]
        }
        return obj; 
      })

      console.log("arr",arr);
      setTable_lables(arr)
      
    }
  }
},[])
  const [table_label, setTable_lables] = useState([]);
  const [editedKey2, setEditedKey2] = useState("");
  const [editedValue2, setEditedValue2] = useState({});
  const [visible2, setVisible2] = useState(false);
  const { handleSubmit, control, register, watch, formState, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  });

  let { errors } = formState;
  const handleEdit2 = (ind) => {
    console.log("ind", ind);
    setEditedKey2(ind);
    setEditedValue2(table_label[ind]);
  };
  const handleDelete2 = (ind) => {
    table_label.splice(ind, 1);

    setTable_lables([...table_label]);
  };

  const handleInputChange2 = (event) => {
    console.log("key", event.target.checked);

    if (event.target.type == "text") {
      let obj = { ...editedValue2, value: event.target.value };
      setEditedValue2(obj);
    } else {
      let obj = { ...editedValue2, flag: event.target.checked };
      setEditedValue2(obj);
    }

    // setEditedValue2(event.target.value);
  };

  const handleUpdate2 = (ind) => {
    console.log("key", ind);
    let arr = [...table_label];
    console.log("edi", editedValue2);
    arr[ind] = editedValue2;

    setTable_lables(arr);
    // setLabels(updatedLabels);
    setEditedKey2("");
    setEditedValue2([]);
  };
  
  
  const onSubmit = (data) => {
    console.log("submitted Data", data);
    let obj={}
    table_label.forEach((item)=>{
      let ob={

        [`${item.value}`]: item.flag,
      };
      obj={
        ...obj, ...ob
      }
       
    });



    if (data !== "") {
      const payload={
          company_name:data.company_name ,
          contact_no:data.contact_no ,
          email: data.email,
          password:data.password,
          company_type: data.company_type,
          industry: data.industry,
          organization:data.organization,
          gst_no: data.gst_no,
          pan_no: data.pan_no,
          website: data.website,
          configuration:obj
      }  
     
      console.log("payload",payload);
      update_client_api({client_id:client_id},payload).then((res) => {
        console.log("res", res);

        toast.success("Client updated successfully !", {
            autoClose: 1000,
        });
        setTimeout(() => {
            navigate("/client");
        }, [1100])
    }, (err) => {
        console.log("err", err);
        if (err.response.data.data.statusCode === 400) {
          //onsole.log("err.response", err.response);
          toast.error(err.response.data.data.response, {
            autoClose: 2000,
          });
        }else{
          toast.error("Something went wrong !", {
            autoClose: 2000,
          });
        }
        
    })
      
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Addtablelable
        visible={visible2}
        setVisible={setVisible2}
        table_label={table_label}
        setTable_lables={setTable_lables}
      />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    navigate("/client");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              Edit Client
            </p>
          </div>



          <div className="card-body">
            <div className="EditClient container-fluid">
              <p className="inter-bold mb-0" style={{ fontSize: "20px" }}>
                Client Details
              </p>
              <hr className="my-2" />

              <div className="row my-4">

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    Company Name<span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.company_name
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type='text'
                      id="company_name"
                      name="company_name"
                      placeholder="Enter company name"
                      defaultValue={company_name&&company_name}
                      {...register("company_name")}
                    />
                    {errors.company_name && (
                      <span className="text-danger err-msg">
                        {errors.company_name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    Contact No:<span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.contact_no
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type='text'
                      id="contact_no"
                      name="contact_no"
                      maxLength={10}
                      placeholder="Enter contact number"
                      defaultValue={contact_no&&contact_no}
                      {...register("contact_no")}
                    />
                    {errors.contact_no && (
                      <span className="text-danger err-msg">
                        {errors.contact_no.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    Email<span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.email
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type='text'
                      id="email"
                      name="email"
                      placeholder="Enter email address"
                      defaultValue={email&&email}
                      {...register("email")}
                    />
                    {errors.email && (
                      <span className="text-danger err-msg">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    Company Type<span>
                      {/* <i style={{ color: "red" }}>*</i> */}
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.company_type
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type='text'
                      id="company_type"
                      name="company_type"
                      placeholder="Enter company type"
                      defaultValue={company_type&&company_type}
                      {...register("company_type")}
                    />
                    {errors.company_type && (
                      <span className="text-danger err-msg">
                        {errors.company_type.message}
                      </span>
                    )}
                  </div>

                </div>
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    Industry<span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.industry
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type='text'
                      id="industry"
                      name="industry"
                      placeholder="Enter industry"
                      defaultValue={industry&&industry}
                      {...register("industry")}
                    />
                    {errors.industry && (
                      <span className="text-danger err-msg">
                        {errors.industry.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    Organization<span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.organization
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type='text'
                      id="organization"
                      name="organization"
                   
                      placeholder="Enter organization"
                      defaultValue={organization&&organization}
                      {...register("organization")}
                    />
                    {errors.organization && (
                      <span className="text-danger err-msg">
                        {errors.organization.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    GST No:<span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.gst_no
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type='text'
                      id="gst_no"
                      name="gst_no"
                      maxLength={15}
                      placeholder="Enter GST number"
                      defaultValue={gst_no&&gst_no}
                      {...register("gst_no")}
                    />
                    {errors.gst_no && (
                      <span className="text-danger err-msg">
                        {errors.gst_no.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    PAN No:<span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.pan_no
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type='text'
                      id="pan_no"
                      name="pan_no"
                      maxLength={10}
                      placeholder="Enter PAN number"
                      defaultValue={pan_no&&pan_no}
                      {...register("pan_no")}
                    />
                    {errors.pan_no && (
                      <span className="text-danger err-msg">
                        {errors.pan_no.message}
                      </span>
                    )}
                  </div>

                </div>
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    Website<span>
                      {/* <i style={{ color: "red" }}>*</i> */}
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.website
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type='text'
                      id="website"
                      name="website"
                      placeholder="Enter website"
                      defaultValue={website&&website}
                      {...register("website")}
                    />
                    {errors.website && (
                      <span className="text-danger err-msg">
                        {errors.website.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label style={{ fontSize: "14px" }} className="col-form-label col-md-3">{"Role"}
                    <span className="text-danger">
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>
                  <div className="col-md-9">
                    <Controller
                      name="role"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead"
                          labelKey="name"
                          size='sm'
                          multiple={false}
                          options={roleList.data}
                        //  loading={roleList.loading}
                          placeholder="Select role "
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.role ? true : false}
                        />
                      )}
                    />
                    <span className="text-danger">{errors.role?.message}</span>
                  </div>
                </div> */}
               
              </div>
            </div>

            <div >
            <hr />
            <div className="d-flex justify-content-between">
              <p className="mb-2 ms-3" style={{ fontSize: "24px" }}>
                Configuration
              </p>
              <button
                className="btn btn-pink px-4 me-3 float-end"
                onClick={() => {
                  setVisible2(true);
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                {"Add Configuration"}
              </button>
            </div>

            <hr />
            <table className="table table-striped table-sm" width={"100%"}>
              <thead className="table-grey text-ceter inter">
                <tr>
                  {/* <th>Table Label Key</th> */}
                  <th>Module</th>
                  <th>Activation</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {table_label.map((item, ind) => (
                  <tr key={`${item.key}${ind}`}>
                    {/* <td>{item.key}</td> */}
                    <td>
                      {editedKey2 === ind ? (
                        <input
                          value={editedValue2.value}
                          onChange={(e) => handleInputChange2(e)}
                        />
                      ) : (
                        item.value
                      )}
                    </td>
                    <td>
                      {editedKey2 === ind ? (
                        <div className="form-check form-switch ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={editedValue2.flag}
                            onChange={(e) => handleInputChange2(e)}
                          />
                        </div>
                      ) : item.flag ? (
                        "True"
                      ) : (
                        "False"
                      )}
                    </td>
                    <td>
                      {editedKey2 === ind ? (
                        <button
                          className="btn btn-yellow px-3 btn-sm"
                          onClick={() => handleUpdate2(ind)}
                        >
                          Update
                        </button>
                      ) : (
                        <React.Fragment>
                          <div className="editIcon me-2">
                            <Edit2
                              color="#FFFFFF"
                              size={18}
                              className="cursor-pointer"
                              onClick={() => handleEdit2(ind)}
                            />
                          </div>

                          <div
                            className="deleteIcon me-2"
                            onClick={() => handleDelete2(ind)}
                          >
                            <Trash2 color="#FFFFFF" size={18} />
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-3">

            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditClient