import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from 'react-tooltip'
import { update_module_api, update_screen_api } from "../../api";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Screen name is required !"),
  })
  .required();

function EditScreen(props) {
  const [visible, setVisible] = useState(false);
  const { register, handleSubmit, formState, control, reset,setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;


  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data !== "") {
        const reqPayload = {
            screen_name: data.name,
            display_name: data.display_name
          };

      console.log("reqPayload", reqPayload);

      update_screen_api( {screen_id:props.data.screen_id},reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Screen updated Successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchScreen();
          }
        },
        (err) => {
          console.log("err.response.status", err.response.status);
          if (err.response.status === 400) {
            console.log("err.response", err.response);
            toast.error(err.response, {
              autoClose: 2000,
            });
            reset();
          }
        }
      )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
    
      <Tooltip id={"edit-tooltip"} place="top" />
      <div data-tooltip-id={"edit-tooltip"}
        data-tooltip-content="Edit Screen" className="editIcon me-2">
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            setVisible(true);
          }}
        />
      </div>
    
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Update Screen"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form action="">
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">
             Screen Name
                <span className="text-danger">
                <i style={{ color: "red" }}>*</i>
                </span>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="name"
                    name="name"
                    placeholder="Enter module name"
                    defaultValue={props.data !== undefined && props.data !== '' ? props.data.screen_name : ''}
                    {...register("name")}
                  />
                  <span className="text-danger">
                    {errors.name?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">
                 Display Name
                </label>
                <div className="col-md-8">
                  <textarea
                    type="text"
                    className="form-control form-control-sm"
                    id="display_name"
                    name="display_name"
                    placeholder="Enter remark"
                    defaultValue={props.data.display_name !== null && props.data !== '' ? props.data.display_name : ''}
                    rows={2}
                    {...register("display_name")}
                  />

                </div>
              </div>
              
            </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  );
}

export default EditScreen;
