import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "react-tooltip";
import { update_channel_master_api, update_subcription_api } from "../../api";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    channel_domain_url: yup.string().required("Channel URL is required !"),
    channel_description: yup.string().required("Channel description is required !"),
  })
  .required();

function UpdateChannelMaster(props) {
  const [visible, setVisible] = useState(false);
  const { register, handleSubmit, formState, control, reset, setValue, watch } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    if(visible){

      reset()   
    }
      
  }, [visible]);
  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data !== "") {
      const reqPayload = {
        channel_domain_url: data.channel_domain_url,
        channel_description: data.channel_description,
      };
      console.log("reqPayload", reqPayload);

      update_channel_master_api({ uuid: props.data.uuid }, reqPayload)
        .then(
          (res) => {
            console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Channel updated successfully !", {
                autoClose: 2000,
              });
              setVisible(false);
              props.fetchChannels(props.page, 10);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <div
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content="Edit Channel"
        className="editIcon me-2"
      >
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
      {/* </OverlayTrigger> */}
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Update Channel"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Channel Domain URL
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="channel_domain_url"
                  name="channel_domain_url"
                  placeholder="Enter channel url "
                  defaultValue={props.data.channel_domain_url}
                  {...register("channel_domain_url")}
                />
                <span className="text-danger">
                  {errors.channel_domain_url?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Channel Description
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="channel_description"
                  name="channel_description"
                  placeholder="Enter  channel description"
                  defaultValue={props.data.channel_description}
                  {...register("channel_description")}
                />
                <span className="text-danger">
                  {errors.channel_description?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default UpdateChannelMaster;
