import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from 'react-tooltip';
import { Edit2 } from 'react-feather';
import { Modal } from 'react-bootstrap';
import { update_document_type } from '../../../api';

const schema = yup
    .object()
    .shape({
        docType: yup
            .string()
            .required("Document type name is required !"),
    })
    .required();


function EditDocumentType(props) {

    const [visible, setVisible] = useState(false);

    const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    }); // initialise the hook

    let { errors } = formState;


    useEffect(() => {
        if (visible) {
            setValue("docType", props.data.name);
        }
    }, [visible]);

    const onSubmit = (data) => {
        

        if (data !== "") {
            const reqPayload = {
                uuid: props.data.uuid,
                name: data.docType,
            };

            

            update_document_type(reqPayload)
                .then(
                    (res) => {
                        
                        if (res.status === 200 || res.status === 201) {
                            toast.success("Document type update Successfully !", {
                                autoClose: 3000,
                            });
                            setVisible(false);
                            props.fetchDocumentTypes(props.page, props.entriesPerPage);
                        }
                    },
                    (err) => {
                        
                        if (err.response.status === 400) {
                            
                            toast.error(err.response.data.message, {
                                autoClose: 2000,
                            });

                        }
                    }
                )
                .catch((error) => {
                    
                    toast.error("Something went wrong, please try again !", {
                        autoClose: 2000,
                    });
                });
        } else {
            errors.showMessages();
        }
    };


    return (
        <React.Fragment>
            <Tooltip id={"delete-tooltip"} place="top" />
            <div
                data-tooltip-id={"delete-tooltip"}
                data-tooltip-content={props.constLabel?.lbl_edit_doc_type ? props.constLabel.lbl_edit_doc_type : "Edit Document Type"}
                className="editIcon me-2">
                <Edit2
                    color="#FFFFFF"
                    size={18}
                    className="cursor-pointer"
                    onClick={() => {
                        // setUpdateForm(val)
                        setVisible(true);
                    }}
                />
            </div>
            <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>{props.constLabel?.lbl_update_doc_type ? props.constLabel.lbl_update_doc_type : "Update Document Type"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="form-group row">
                            <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                                {props.constLabel?.lbl_doc_type ? props.constLabel.lbl_doc_type : "Document Type Name"}
                                <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className={errors.docType ? "form-control  is-invalid"
                                        : "form-control"}
                                    id="name"
                                    name="docType"
                                    placeholder="Enter document type name"
                                    // defaultValue={props.data.name}
                                    {...register("docType")}
                                />
                                <span className="text-danger err-msg">
                                    {errors.docType?.message}
                                </span>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="btn btn-yellow px-3"
                    >
                        {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
                    </button>
                </Modal.Footer>
            </Modal>

        </React.Fragment>
    )
}

export default React.memo(EditDocumentType)