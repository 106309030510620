
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Edit2, Plus } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from 'react-tooltip'
import {  get_module_screen_api, save_module_screen_api, update_module_screen_api, } from "../../api";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
    .object()
    .shape({
        screen: yup
            .array()
            .required("Please select screen !")
            .min(1, "Please select screen !"),
    })
    .required();

function AddScreen(props) {
    const [visible, setVisible] = useState(false);
    const [screen_present,setScreen_present]=useState(false);

    const { register, handleSubmit, formState, control, reset, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    }); // initialise the hook

    let { errors } = formState;

    useEffect(() => {
        console.log("props", props);
        if(visible){
            fetchScreen(props.data.module_id)
        }
    }, [visible])
    const onSubmit = (data) => {
        console.log("submitted Data", data);

        if (data !== "") {
            const reqPayload = {
                module_id: props.data.module_id,
                screens:data.screen
               
            };

            
          if(screen_present){
            console.log("reqPayload if", reqPayload);
            
            update_module_screen_api({uuid:props.data.module_id}, reqPayload).then(
                (res) => {
                  console.log("res", res);
                  if (res.status === 200 || res.status === 201) {
                    toast.success("Screen updated successfully !", {
                      autoClose: 3000,
                    });
                    setVisible(false);
                    props.fetchModule();
                  }
                },
                (err) => {
                  console.log("err.response.status", err.response.status);
                  if (err.response.status === 400) {
                    console.log("err.response", err.response);
                    toast.error(err.response, {
                      autoClose: 2000,
                    });
                    reset();
                  }
                }
              )
                .catch((error) => {
                  console.log("error", error);
                  toast.error("Something went wrong, please try again !", {
                    autoClose: 2000,
                  });
                });
          }
          else{
            console.log("reqPayload else", reqPayload);
            
            save_module_screen_api( reqPayload).then(
                (res) => {
                  console.log("res", res);
                  if (res.status === 200 || res.status === 201) {
                    toast.success("Screen added successfully !", {
                      autoClose: 3000,
                    });
                    setVisible(false);
                    props.fetchModule();
                  }
                },
                (err) => {
                  console.log("err.response.status", err.response.status);
                  if (err.response.status === 400) {
                    console.log("err.response", err.response);
                    toast.error(err.response, {
                      autoClose: 2000,
                    });
                    reset();
                  }
                }
              )
                .catch((error) => {
                  console.log("error", error);
                  toast.error("Something went wrong, please try again !", {
                    autoClose: 2000,
                  });
                });
          }
          
        } else {
            errors.showMessages();
        }
    };

    function fetchScreen(id) {

       
        
        get_module_screen_api({module_id:id}).then(
          (res) => {
            console.log("Screen res", res);
    
          if(res.data.data.response.length>0){
            setValue("screen",res.data.data.response);
            setScreen_present(true)
          }else{
            setValue("screen",[])
          }
    
          },
          (err) => {
            console.log("err", err);
    
           
          }
        );
      }
    return (
        <React.Fragment>

            <Tooltip id={"add-tooltip"} place="top" />
            <div data-tooltip-id={"add-tooltip"}
                data-tooltip-content="Add Screen" className="pdrIcon ">
                <Plus
                    color="#FFFFFF"
                    size={18}
                    className="cursor-pointer"
                    onClick={() => {
                        // setUpdateForm(val)
                        setVisible(true);
                    }}
                />
            </div>
            {/* </OverlayTrigger> */}
            <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>{"Add Screen"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">

                        <div className="form-group row mt-2">
                            <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">{"Screen"}
                                <span className="text-danger">
                                    <i style={{ color: "red" }}>*</i>
                                </span>
                            </label>
                            <div className="col-md-8">
                                <Controller
                                    name="screen"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead"
                                            labelKey="screen_name"
                                            multiple={true}
                                            options={props.screen_list}
                                            placeholder="Select screen"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.screen ? true : false}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.screen?.message}</span>
                            </div>
                        </div>


                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="f-16 btn btn-yellow inter-bold"
                    >
                        {"Save"}
                    </button>
                </Modal.Footer>
            </Modal>

        </React.Fragment>
    );
}

export default AddScreen;
