import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

import { Pagination } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import {
  get_transaction_api,
  save_transaction_api,
  delete_transaction_api,
} from "../../api";
import { calMaxPage } from "../../common/Functions/CommonFunctions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    transactionName: yup.string().required("Transaction name is required !"),
  })
  .required();
export function Transaction() {
  const [page, setPage] = useState(0);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [visible, setVisible] = useState(false);

  const [transactionList, settransactionList] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    fetchtransactions(page, entriesPerPage);
  }, []);
  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible]);

  function fetchtransactions(a, b) {
    settransactionList({ ...transactionList, data: [], loading: true });

    const payload = {
      entriesPerPage: b,
      pageNo: a,
    };

    // console.log("a",a)
    // console.log("b",b)
    get_transaction_api(payload).then(
      (res) => {
        console.log("res", res.data.data);
        settransactionList({
          ...transactionList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage(res.data.total));
      },
      (err) => {
        settransactionList({
          ...transactionList,
          data: [],
          loading: false,
          totalRecords: "",
        });
        console.log("err", err);
      }
    );
  }

  const delTransaction = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure you want to delete \n" + `${val.transactionName}` + " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_transaction_api(val.uuid ).then(
          (res) => {
            console.log("res", res);
            toast.success("Transaction deleted successfully", {
              autoClose: 2000,
            });

            let value = transactionList.totalRecords % 10;
            if (value == 1 && page !== 0) {
              fetchtransactions(page - 1, 10);
              setPage(page - 1);
              setSrNo(srNo - 10);
            } else {
              fetchtransactions(page, 10);
            }
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  const onSubmit = (data) => {
    console.log("data", data);
    if (data !== "") {
      const reqPayload = {
        transactionName: data.transactionName,
        transactionDescription: data.transactionDescription,
      };

      console.log("reqPayload", reqPayload);

      
      save_transaction_api(reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Transaction created successfully !", {
              autoClose: 2000,
            });
            fetchtransactions(page, 10);
            setVisible(false);
            reset();
          }
        },
        (err) => {
          toast.error("Something went wrong !", {
            autoClose: 2000,
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Add Transaction"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Transaction Name 
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="transactionName"
                  name="transactionName"
                  placeholder="Enter transaction name"
                  {...register("transactionName")}
                />
                <span className="text-danger">
                  {errors.transactionName?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Transaction Description

              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="transactionDescription"
                  name="transactionDescription"
                  placeholder="Enter transaction description"
                  {...register("transactionDescription")}
                />

              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="roles">
        <div className="d-flex justify-content-between">
          <h1 className="page-heading inter-bold">{"Transaction"}</h1>
          <button
            className="btn btn-pink px-4"
            onClick={() => {
              setVisible(true);
            }}
          >
            <FontAwesomeIcon
              style={{ color: "#fff" }}
              icon={faPlus}
              size="sm"
            />{" "}
            Create New Transaction
          </button>
        </div>

        <div className="row">
          <div className="col-12">
            {/* <div className='entriesPerPage inter-bold d-flex align-items-end'>
                            <p className='mb-0'>Show
                                <select className='mx-1' defaultValue={'10'} onChange={(e) => {
                                    setEntriesPerPage(e.target.value);
                                    fetchtransactions(page, e.target.value)
                                }}>
                                    <option value={'10'}>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>Entries</p>

                        </div> */}
            <div className="card border-0 mt-3 mb-4">
              <div className="card-body">
                {transactionList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : transactionList.data?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table mt-2 table-striped table-sm">
                      <thead className="table-grey  inter-bold">
                        <tr className="">
                          <th scope="col">{"Sr. No"}</th>
                          <th scope="col">{"Transaction Name"}</th>
                          <th scope="col">{"Transaction Description"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody className=" inter">
                        {transactionList.data.map((val, i) => (
                          <tr key={i}>
                            <td style={{ verticalAlign: "middle" }}>
                              {parseInt(i) + 1 + parseInt(srNo)}
                            </td>
                            <td>{val.transactionName}</td>
                            <td>{val.transactionDescription?val.transactionDescription:"NA"}</td>

                            <td className="">
                             

                              <Tooltip id={"delete-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"delete-tooltip" + i}
                                data-tooltip-content="Delete Transaction"
                                onClick={() => delTransaction(val)}
                                className="deleteIcon m-1 cursor_pointer"
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-danger mb-0">
                    No Transacation's Found
                  </p>
                )}

                {transactionList.loading ? null : transactionList.data?.length > 0 ? (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
                      transactionList.data.length +
                      entriesPerPage * (page+1) -
                      entriesPerPage
                    } of ${transactionList.totalRecords} entries`}</p>
                    <Pagination>
                      <Pagination.Prev
                        disabled={page === 0 ? true : false}
                        onClick={() => {
                          fetchtransactions(page - 1, 10);
                          setSrNo((prevC) =>
                            page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                          );
                          setPage(page - 1);
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{page+1}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          ((page+Number(1)) === maxPage ||
                          maxPage === 0 )
                          
                            ? true
                            : false
                        }
                        onClick={() => {
                          // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                          fetchtransactions(page + 1, 10);
                          setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                          setPage(page + 1);
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
