import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

import { Pagination } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import {
  get_scenario_mapping_api,
  delete_scenario_mapping_api,
  save_scenario_mapping_api,
} from "../../api";
import { calMaxPage, calMaxPage_new } from "../../common/Functions/CommonFunctions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    scenario: yup.string().required("Scenario is required !"),
    constantName: yup.string().required("Constant name is required !"),
    noMovementTransaction: yup.string()
    .required("Number of  movement transaction is required !")
    .matches(/^[1-9]+(\.[1-9]+)?$/, "Enter valid quantity !")
    .typeError("Enter valid quantity !"),
    firstMovementCode: yup.string().required("First movement code is required !"),
    secondMovementCode: yup.string().when(["noMovementTransaction"], (val, schema) => {
      //  console.log("challanType",challanType)

      if (val>1) {
        return schema.required("Second movement code is required !");
      } else {
        return schema;
      }
    }),
    
  })
  .required();
export function ScenarioMapping() {
  const [page, setPage] = useState(0);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [visible, setVisible] = useState(false);

  const [scenariomappingList, setscenariomappingList] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    fetchsenario(page, entriesPerPage);
  }, []);
  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible]);

  function fetchsenario(a, b) {
    setscenariomappingList({ ...scenariomappingList, data: [], loading: true });

    const payload = {
      entriesPerPage: b,
      pageNo: a,
    };

    // console.log("a",a)
    // console.log("b",b)

    get_scenario_mapping_api(payload).then(
      (res) => {
        console.log("res", res.data.data);
        setscenariomappingList({
          ...scenariomappingList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        console.log("calMaxPage_new(res.data.total,entriesPerPage)",calMaxPage_new(res.data.total,entriesPerPage))

        setMaxPage(calMaxPage_new(res.data.total,entriesPerPage));
      },
      (err) => {
        setscenariomappingList({
          ...scenariomappingList,
          data: [],
          loading: false,
          totalRecords: "",
        });
        console.log("err", err);
      }
    );
  }

  const delScenarioMapping = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure you want to delete \n" + `${val.scenario}` + " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_scenario_mapping_api(val.uuid ).then(
          (res) => {
            console.log("res", res);
            toast.success("Scenario mapping deleted successfully !", {
              autoClose: 2000,
            });

            let value = scenariomappingList.totalRecords % 10;
            if (value == 1 && page !== 0) {
              fetchsenario(page - 1, 10);
              setPage(page - 1);
              setSrNo(srNo - 10);
            } else {
              fetchsenario(page, 10);
            }
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  const onSubmit = (data) => {
    console.log("data", data);
    if (data !== "") {
      const reqPayload = {
        scenario: data.scenario,
        constantName: data.constantName,
        noMovementTransaction: data.noMovementTransaction,
        firstMovementCode: data.firstMovementCode,
        secondMovementCode: data.secondMovementCode,
      };

      console.log("reqPayload", reqPayload);

 
      save_scenario_mapping_api(reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Scenario mapping created successfully !", {
              autoClose: 2000,
            });
            fetchsenario(page, 10);
            setVisible(false);
            reset();
          }
        },
        (err) => {
          toast.error("Something went wrong !", {
            autoClose: 2000,
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Add Scenario Mapping"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Scenario
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="scenario"
                  name="scenario"
                  placeholder="Enter scenario name"
                  {...register("scenario")}
                />
                <span className="text-danger">
                  {errors.scenario?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Constant Name
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="constantName"
                  name="constantName"
                  placeholder="Enter constant name"
                  {...register("constantName")}
                />
                <span className="text-danger">
                  {errors.constantName?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
               No. of Movement Transaction
               <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="noMovementTransaction"
                  name="noMovementTransaction"
                  placeholder="Enter number of movement traction"
                  {...register("noMovementTransaction")}
                />
  <span className="text-danger">
                  {errors.noMovementTransaction?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                First Movement Code
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="firstMovementCode"
                  name="firstMovementCode"
                  placeholder="Enter first movement code"
                  {...register("firstMovementCode")}
                />
  <span className="text-danger">
                  {errors.firstMovementCode?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Second Movement Code
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="secondMovementCode"
                  name="secondMovementCode"
                  placeholder="Enter first movement code"
                  {...register("secondMovementCode")}
                />
<span className="text-danger">
                  {errors.secondMovementCode?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                Third Movement Code

              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="thirdMovementCode"
                  name="thirdMovementCode"
                  placeholder="Enter first movement code"
                  {...register("thirdMovementCode")}
                />

              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="roles">
        <div className="d-flex justify-content-between">
          <h1 className="page-heading inter-bold">{"Scenario Mapping"}</h1>
          <button
            className="btn btn-pink px-4"
            onClick={() => {
              setVisible(true);
            }}
          >
            <FontAwesomeIcon
              style={{ color: "#fff" }}
              icon={faPlus}
              size="sm"
            />{" "}
            Create New Scenario Mapping
          </button>
        </div>

        <div className="row">
          <div className="col-12">
            {/* <div className='entriesPerPage inter-bold d-flex align-items-end'>
                            <p className='mb-0'>Show
                                <select className='mx-1' defaultValue={'10'} onChange={(e) => {
                                    setEntriesPerPage(e.target.value);
                                    fetchsenario(page, e.target.value)
                                }}>
                                    <option value={'10'}>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>Entries</p>

                        </div> */}
            <div className="card border-0 mt-3 mb-4">
              <div className="card-body">
                {scenariomappingList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : scenariomappingList.data?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table mt-2 table-striped table-sm">
                      <thead className="table-grey  inter-bold">
                        <tr className="">
                          <th scope="col">{"Sr. No"}</th>
                          <th scope="col">{"Constant Name"}</th>
                          <th scope="col">{"Scenario"}</th>
                          <th scope="col">{"No. of Movement Transaction"}</th>
                          <th scope="col">{"First Movement Code"}</th>
                          <th scope="col">{"Second Movement Code"}</th>
                          <th scope="col">{"Third Movement Code"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody className=" inter">
                        {scenariomappingList.data.map((val, i) => (
                          <tr key={i}>
                            <td style={{ verticalAlign: "middle" }}>
                              {parseInt(i) + 1 + parseInt(srNo)}
                            </td>
                            <td>{val.constantName}</td>
                            <td>{val.scenario}</td>
                            <td>{val.noMovementTransaction?val.noMovementTransaction:"NA"}</td>
                            <td>{val.firstMovementCode}</td>
                            <td>{val.secondMovementCode?val.secondMovementCode:"NA"}</td>
                            <td>{val.thirdMovementCode?val.thirdMovementCode:"NA"}</td>
                           

                            <td className="">
                             

                              <Tooltip id={"delete-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"delete-tooltip" + i}
                                data-tooltip-content="Delete Scenario Mapping"
                                onClick={() => delScenarioMapping(val)}
                                className="deleteIcon m-1 cursor_pointer"
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-danger mb-0">
                    No Transacation's Found
                  </p>
                )}

                {scenariomappingList.loading ? null : scenariomappingList.data?.length > 0 ? (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
                      scenariomappingList.data.length +
                      entriesPerPage * (page+1) -
                      entriesPerPage
                    } of ${scenariomappingList.totalRecords} entries`}</p>
                    <Pagination>
                      <Pagination.Prev
                        disabled={page === 0 ? true : false}
                        onClick={() => {
                          fetchsenario(page - 1, 10);
                          setSrNo((prevC) => prevC - parseInt(entriesPerPage)
                          );
                          setPage(page - 1);
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{page+1}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                         ( (page+Number(1)) == maxPage ||
                          maxPage == 0 )
                            ? true
                            : false
                        }
                        onClick={() => {
                          // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                         
                          fetchsenario(page + 1, 10);
                          setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                          setPage(page + 1);
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
