import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from 'react-tooltip'
import { update_user_api } from "../../api";
import { Typeahead } from "react-bootstrap-typeahead";


const schema = yup
    .object()
    .shape({
        name: yup
            .string()
            .required("Please enter name"),
        role: yup
            .array()
            .min(1, "Please select role")
            .required("Please select role"),
        app: yup
        .array()
        .required("Please select application !")
        .min(1, "Please select application !"), 
        client: yup
        .array()
        .required("Please select client !")
        .min(1, "Please select client !"), 
        email: yup
            .string()
            .required("Please enter email")
            .email("Please enter valid email"),
        username: yup
            .string()
            .required("Please enter username"),
        // .matches(/^[a-zA-Z0-9]*$/, "Only alphabets and number allowed !"),
    })
    .required();


function EditUser(props) {
  const [visible, setVisible] = useState(false);
  const { register, handleSubmit, formState, control, reset, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    console.log("props", props);
    if (visible == true) {
      console.log("inside", props);
      let a = props.app_list.filter((item) => item.app_id == props.data.app_id);
      let b=props.clientlist.filter((item)=>item.client_id==props.data.client_id);
      let c=props.role_list.filter((item)=>item.role_id==props.data.role_id);
      setValue("app", a);
      setValue("client",b);
      setValue("role",c)
      
    }
  }, [visible])
  const onSubmit = (data) => {
    console.log("submitted Data", data);

    if (data !== "") {
        const reqPayload=   {
            username:data.username ,
            email: data.email,
            password:data.password,
            client_id: data.client[0].client_id,
            role_name:data.role[0].name ,
            role_id:data.role[0].role_id ,
            app_id:data.app[0].app_id ,
            name: data.name
        }

      console.log("reqPayload", reqPayload);

      update_user_api({ user_id: props.data.user_id }, reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("User updated Successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchSubcription();
          }
        },
        (err) => {
          console.log("err.response.status", err.response.status);
          if (err.response.status === 400) {
            console.log("err.response", err.response);
            toast.error(err.response, {
              autoClose: 2000,
            });
            reset();
          }
        }
      )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>

      <Tooltip id={"edit-tooltip"} place="top" />
      <div data-tooltip-id={"edit-tooltip"}
        data-tooltip-content="Edit User" className="editIcon me-2">
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
      {/* </OverlayTrigger> */}
      <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}
       
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Update User"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form action="" autoComplete="off">

                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label col-12 col-md-4"
                                        >
                                            Name
                                            <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <input
                                                type="text"
                                                className={errors.name ? "form-control form-control-sm is-invalid"
                                                    : " form-control form-control-sm"}
                                                id="name"
                                                name="name"
                                                placeholder="Enter name"
                                                {...register("name")}
                                            />
                                            {errors.name
                                                && <span className='text-danger err-msg'>{errors.name.message}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label col-12 col-md-4"
                                        >
                                            User Name
                                            <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <input
                                                type="text"
                                                className={errors.username ? "form-control form-control-sm is-invalid"
                                                    : " form-control form-control-sm"}
                                                id="username"
                                                name="username"
                                                placeholder="Enter user name"
                                                {...register("username")}
                                            />
                                            {errors.username
                                                && <span className='text-danger err-msg'>{errors.username.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label col-12 col-md-4"
                                        >
                                            Email
                                            <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <input
                                                type="email"
                                                className={errors.email ? "form-control form-control-sm is-invalid"
                                                    : " form-control form-control-sm"}
                                                id="email"
                                                name="email"
                                                placeholder="Enter email"
                                                {...register("email")}
                                            />
                                            {errors.email
                                                && <span className='text-danger err-msg'>{errors.email.message}</span>}

                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label col-12 col-md-4"
                                        >
                                            Password
                                            <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">

                                            <div className="input-group">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className={errors.password ? "form-control form-control-sm is-invalid"
                                                        : " form-control form-control-sm"}
                                                    id="password"
                                                    name="password"

                                                    placeholder="Enter password"
                                                    {...register("password")}
                                                    autoComplete="new-password"
                                                />
                                                <span className="input-group-text">
                                                    {showPassword ?
                                                        <FontAwesomeIcon
                                                            style={{ color: "#212529", cursor: 'pointer' }}
                                                            icon={faEyeSlash}
                                                            size="sm"
                                                            onClick={() => setShowPassword(false)}
                                                        />
                                                        :
                                                        <FontAwesomeIcon
                                                            style={{ color: "#212529", cursor: 'pointer' }}
                                                            icon={faEye}
                                                            size="sm"
                                                            onClick={() => setShowPassword(true)}
                                                        />
                                                    }
                                                </span>

                                            </div>

                                            {errors.password
                                                && <span className='text-danger err-msg'>{errors.password.message}</span>}
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="form-label col-12 col-md-4"
                                        >
                                            Confirm Password
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <div className="input-group">
                                                <input
                                                    type={showPassword2 ? "text" : "password"}
                                                    className={errors.confirmPassword ? "form-control form-control-sm is-invalid"
                                                        : " form-control form-control-sm"}
                                                    id="confirmPassword"
                                                    name="confirmPassword"

                                                    placeholder="Enter confirm password"
                                                    {...register("confirmPassword")}
                                                    autoComplete="new-password"
                                                />

                                                <span className="input-group-text">
                                                    {showPassword2 ?
                                                        <FontAwesomeIcon
                                                            style={{ color: "#212529", cursor: 'pointer' }}
                                                            icon={faEyeSlash}
                                                            size="sm"
                                                            onClick={() => setShowPassword2(false)}
                                                        />
                                                        :
                                                        <FontAwesomeIcon
                                                            style={{ color: "#212529", cursor: 'pointer' }}
                                                            icon={faEye}
                                                            size="sm"
                                                            onClick={() => setShowPassword2(true)}
                                                        />
                                                    }
                                                </span>

                                            </div>
                                            {errors.confirmPassword
                                                && <span className='text-danger err-msg'>{errors.confirmPassword.message}</span>}
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <div className="form-group row mb-3">


                                        <label style={{ fontSize: "14px" }} className="form-label col-12 col-md-4">{"Application"}
                                            <span className="text-danger">
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <Controller
                                                name="app"
                                                control={control}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Typeahead
                                                        id="basic-typeahead"
                                                        labelKey="app_name"
                                                        size="sm"
                                                        multiple={false}
                                                        options={props.app_list}
                                                       // loading={app_list.loading}
                                                        placeholder="Select application"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        isInvalid={errors.app ? true : false}
                                                    />
                                                )}
                                            />
                                            <span className="text-danger">{errors.app?.message}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row mb-3">


                                        <label style={{ fontSize: "14px" }} className="form-label col-12 col-md-4">{"Role"}
                                            <span className="text-danger">
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <Controller
                                                name="role"
                                                control={control}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Typeahead
                                                        id="basic-typeahead"
                                                        labelKey="name"
                                                        size="sm"
                                                        multiple={false}
                                                        options={props.role_list}
                                                     //   loading={role_list.loading}
                                                        placeholder="Select role"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        isInvalid={errors.role ? true : false}
                                                    />
                                                )}
                                            />
                                            <span className="text-danger">{errors.role?.message}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row mb-3">


                                        <label style={{ fontSize: "14px" }} className="form-label col-12 col-md-4">{"Client"}
                                            <span className="text-danger">
                                                <i style={{ color: "red" }}>*</i>
                                            </span>
                                        </label>
                                        <div className="col-12 col-md-8">
                                            <Controller
                                                name="client"
                                                control={control}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Typeahead
                                                        id="basic-typeahead"
                                                        labelKey="company_name"
                                                        size="sm"
                                                        multiple={false}
                                                        options={props.clientlist}
                                                       // loading={clientlist.loading}
                                                        placeholder="Select client"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        isInvalid={errors.client ? true : false}
                                                    />
                                                )}
                                            />
                                            <span className="text-danger">{errors.client?.message}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  );
}

export default EditUser;

