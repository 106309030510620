import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCog } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "react-bootstrap";
import { Trash2 } from "react-feather";
import {
  get_document_type,
  delete_document_type,
  create_document_type,
} from "../../../api";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import {
  calMaxPage_new,
} from "../../../common/Functions/CommonFunctions";
import EditDocumentType from "./EditDocumentType";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "react-bootstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const schema = yup
  .object()
  .shape({
    docType: yup.string().required("Document type name is required !"),
  })
  .required();

function DocumentType() {
  let navigate = useNavigate();

  const [showLabel, setShowLabel] = useState([]);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [docList, setDocList] = useState({
    data: [],
    loading: true,
    total: "",
  });

  const [constLabel, setConstLabel] = useState({});
  const [visible, setVisible] = useState(false);

  

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(null);
  }, []);

  useEffect(() => {
    setPage(1);
    fetchDocumentTypes(page, entriesPerPage);
  }, []);

  function fetchDocumentTypes(pg, epp) {
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
    };
    setDocList({ ...docList, data: [], loading: true });

    get_document_type(payload).then(
      (res) => {
        setDocList({
          ...docList,
          data: res.data.data,
          loading: false,
          total: res.data.total,
        });
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDocList({ ...docList, data: [], loading: false });
      }
    );
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function del_document_type(val) {
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.name + " ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_document_type({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Document Type deleted successfully", {
              autoClose: 2000,
            });
            if (docList.data.length == 1 && page > 1) {
              fetchDocumentTypes(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchDocumentTypes(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        name: data.docType,
      };

      create_document_type(reqPayload)
        .then(
          (res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success("Document type created Successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              fetchDocumentTypes(page, entriesPerPage);
            }
          },
          (err) => {
            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        )
        .catch((error) => {
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="page-heading">
              {constLabel?.lbl_document_api
                ? constLabel.lbl_document_api
                : "Document Type"}
            </h1>

            <div>
             
                <button
                  onClick={() => {
                    setVisible(true);
                    reset();
                  }}
                  className="btn btn-pink px-3 float-end"
                >
                  <FontAwesomeIcon
                    style={{ color: "#fff" }}
                    icon={faPlus}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_create_doc_type
                    ? constLabel.lbl_create_doc_type
                    : "Create Document Type"}
                </button>
              
            </div>
          </div>
        </div>

        <div className="col-12 mt-3">
          <div className="card border-0">
            <div className="card-body ">
              <div className="row ">
                <div className="col-12">
                  {/* <div className="entriesPerPage roboto-bold d-flex justify-content-between align-items-end mb-3">
                    <p className="mb-0">
                      Show
                      <select
                        className="mx-1"
                        defaultValue={"10"}
                        onChange={(e) => {
                          setEntriesPerPage(e.target.value);
                          fetchDocumentTypes(1, e.target.value);
                        }}
                      >
                        <option value={"10"}>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Entries
                    </p>
                    <div className="dropdown">
                      <div
                        className="settignIcon_custom_nc d-flex justify-content-center align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faCog}
                          size="sm"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div> */}

                  {docList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : docList.data?.length > 0 ? (
                    <div className="table-responsive">
                      <table  className="table table-striped table-sm"
                        width={"100%"}>
                        <thead className="table-grey  roboto-bold">
                          <tr className="">
                            <th  scope="col" width="15%">{"SrNo"}</th>
                            
                            
                                <th  scope="col">
                                  {constLabel?.lbl_doc_type_name
                                    ? constLabel.lbl_doc_type_name
                                    : "Name"}
                                </th>
                             
                        
                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" roboto">
                          {docList.data.map((val, i) => (
                            <tr key={i}>
                              <td style={{ verticalAlign: "middle" }}>
                                {parseInt(i) + 1 + parseInt(srNo)}
                              </td>
                             
                              
                                  <td>
                                    {val.name !== null ? val.name : "NA"}
                                  </td>
                                
                              
                              <td className="">
                                
                                  <>
                                    <EditDocumentType
                                      data={val}
                                      constLabel={constLabel}
                                      fetchDocumentTypes={fetchDocumentTypes}
                                      page={page}
                                      entriesPerPage={entriesPerPage}
                                    />
                                  </>
                              

                               
                                  <>
                                    <Tooltip
                                      id={"delete-tooltip"}
                                      place="top"
                                    />
                                    <div
                                      data-tooltip-id={"delete-tooltip"}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_doc_type
                                          ? constLabel.lbl_delete_doc_type
                                          : "Delete Document Type"
                                      }
                                      className="deleteIcon m-1 cursor_pointer"
                                      onClick={() => del_document_type(val)}
                                    >
                                      <Trash2 color="#FFFFFF" size={18} />
                                    </div>
                                  </>
                              
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center my-4"
                      role="alert"
                    >
                      No Document Types Found
                    </div>
                  )}

                  {docList.loading ? null : docList.data?.length > 0 ? (
                    <div className="mt-4 d-flex justify-content-between">
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        docList.total
                      )} of ${docList.total} entries`}</p>
                      <Pagination>
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchDocumentTypes(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > docList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchDocumentTypes(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_update_doc_type
              ? constLabel.lbl_update_doc_type
              : "Create Document Type"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {constLabel?.lbl_doc_type_type
                  ? constLabel.lbl_doc_type_type
                  : "Document Type Name"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.docType ? "form-control  is-invalid" : "form-control"
                  }
                  id="name"
                  name="docType"
                  placeholder="Enter document type name"
                  {...register("docType")}
                />
                <span className="text-danger err-msg">
                  {errors.docType?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn btn-yellow px-3"
          >
            {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default DocumentType;
